import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Header from './shared/Header';
import Footer from './shared/Footer';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import LoadingSpinner from './shared/Loader';
// const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
function Checkout() {
  useEffect(() => {
    getShippingDetails();
    orderSummaryData();
    getUserDetails();
    getCards();
  }, []);

  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [orderSummary, setOrderSummary] = useState([]);
  const [user, setuser] = useState([]);
  const [shippingData, setShippingData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cards, setCards] = useState([]);
  // const createOrder = (data, actions) => {
  //   return actions.order.create({
  //     purchase_units: [
  //       {
  //         amount: {
  //           value: "0.01",
  //         },
  //       },
  //     ],
  //   });
  // };
  // const onApprove = (data, actions) => {
  //   return actions.order.capture();
  // };
  // Sqaure up payment gateway
  const appId = 'sq0idp-NV6yd_59RLHtxGFAJvuA-A';
  const locationId = 'USA';

  async function initializeCard(payments) {
    const card = await payments.card();
    await card.attach('#card-container');

    return card;
  }

  async function createPayment(token) {
    // const body = JSON.stringify({
    //   locationId,
    //   sourceId: token,
    // });
    //
    // const paymentResponse = await fetch('/payment', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body,
    // });
    return orderCheckout(token);
    // if (paymentResponse.ok) {
    //   return paymentResponse.json();
    // }
    //
    // const errorBody = await paymentResponse.text();
    // throw new Error(errorBody);
  }

  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
        )}`;
      }

      throw new Error(errorMessage);
    }
  }

  // status is either SUCCESS or FAILURE;
  function displayPaymentResults(status) {
    const statusContainer = document.getElementById(
        'payment-status-container'
    );
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  }

  document.addEventListener('DOMContentLoaded', async function () {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }

    let payments;
    try {
      payments = window.Square.payments(appId, locationId);
    } catch {
      const statusContainer = document.getElementById(
          'payment-status-container'
      );
      statusContainer.className = 'missing-credentials';
      statusContainer.style.visibility = 'visible';
      return;
    }

    let card;
    try {
      card = await initializeCard(payments);
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }

    // Checkpoint 2.
    async function handlePaymentMethodSubmission(event, paymentMethod) {
      event.preventDefault();

      try {
        // disable the submit button as we await tokenization and make a payment request.
        cardButton.disabled = true;
        const token = await tokenize(paymentMethod);
        const paymentResults = await createPayment(token);
        displayPaymentResults('SUCCESS');

        console.debug('Payment Success', paymentResults);
      } catch (e) {
        cardButton.disabled = false;
        displayPaymentResults('FAILURE');
        console.error(e.message);
      }
    }

    const cardButton = document.getElementById('card-button');
    cardButton.addEventListener('click', async function (event) {
      await handlePaymentMethodSubmission(event, card);
    });
  });
  // Square up payment gateway end
  const getShippingDetails = () => {
    setIsLoading(true);
    const shippingDetailsData = new FormData();
    shippingDetailsData.append('token', localStorage.getItem('token') );
    const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/address/shipping`, shippingDetailsData, params)
          .then(res => {
              setIsLoading(false);
              if (res?.data?.status === 200) {
                setShippingData(res?.data?.data);
                if(res?.data?.data?.length > 0){
                  for(let i=0; i<res?.data?.data?.length; i++){
                    if(res?.data?.data[i]?.default_address === '1'){
                      const newData = {
                        fname:  res?.data?.data[i]?.first_name,
                        lname: res?.data?.data[i]?.last_name,
                        number: res?.data?.data[i]?.mobile_number,
                        email: res?.data?.data[i]?.email,
                        address: res?.data?.data[i]?.address,
                        country: res?.data?.data[i]?.country,
                        state: res?.data?.data[i]?.state,
                        city: res?.data?.data[i]?.city,
                        zip: res?.data?.data[i]?.postal_code,
                        company: res?.data[i]?.data?.company ? res?.data[i]?.data?.company : ''
                      }
                      setInputValue(newData);
                    }
                  }

                }

              }else {

              }
          })
          .catch(error => {
              setIsLoading(false);
          });
  }

  const getUserDetails = () => {
    setIsLoading(true);
    const userDetailsData = new FormData();
    userDetailsData.append('token', localStorage.getItem('token') );
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user`, userDetailsData, params)
        .then(res => {
          setIsLoading(false);
          if (res?.data?.status === 200) {
            setuser(res?.data?.data);
          }else {

          }
        })
        .catch(error => {
          setIsLoading(false);
          // props.changeLogin(true);
        });
  }
  const [inputValues, setInputValue] = useState({
    fname: "",
    lname: "",
    number: "",
    email: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    company: ""
  });
  const [cartValues, setCartValues] = useState({
    payment: '2',
    po : '',
    orderNotes: '',
    savedCard : '1'
  });
  function cartHandleChange(e) {
    const { name, value } = e.target;
    setCartValues({ ...cartValues, [name]: value });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  const [validation, setValidation] = useState({
    fname: "",
    lname: "",
    number: "",
    email: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    company: ""
  });
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    //first validation
    const alphabetCond = /^[a-zA-Z ]*$/;
    if (!inputValues.fname) {
    errors.fname = "First name is required";
    } else if (!inputValues.fname.match(alphabetCond)) {
    errors.fname = "Please enter valid first name";
    } else {
    errors.fname = "";
    }
    //last validation
    if (!inputValues.lname) {
    errors.lname = "Last name is required";
    } else if (!inputValues.lname.match(alphabetCond)) {
    errors.lname = "Please enter valid last name";
    } else {
    errors.lname = "";
    }
    //number validation
    const numberCond = /^[0-9\b]+$/;
    if (!inputValues.number) {
    errors.number = "Number is required";
    }
    // else if (!inputValues.number.match(numberCond)) {
    // errors.number = "Please enter valid number";
    // }
    else {
    errors.number = "";
    }
    // email validation
    const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!inputValues.email) {
      errors.email = "Email is required";
    } else if (!inputValues.email.match(emailCond)) {
      errors.email = "Please enter valid email address";
    } else {
      errors.email = "";
    }
    //address validation
    if (!inputValues.address) {
    errors.address = "Address is required";
    }else {
    errors.address = "";
    }
    //country validation
    if (!inputValues.country) {
      errors.country = "Country is required";
      }else {
      errors.country = "";
    }
    //state validation
    if (!inputValues.state) {
      errors.state = "State is required";
      }else {
      errors.state = "";
    }
    //city validation
    if (!inputValues.city) {
      errors.city = "City is required";
      }else {
      errors.city = "";
    }
    //zip validation
    if (!inputValues.zip) {
      errors.zip = "Zip is required";
      }else {
      errors.zip = "";
    }
    //company validation
    // if (!inputValues.company) {
    //   errors.company = "Company is required";
    //   }else {
    //   errors.company = "";
    // }

    setValidation(errors);
    if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === '' && errors.address === '' && errors.country === '' && errors.state === ''&& errors.city === '' && errors.zip === ''){
      return true;
    }else{
      return false;
    }
  };
  // const checkValidation1 = () => {
  //   let errors = JSON.parse(JSON.stringify(validation1));

  //   //first validation
  //   const alphabetCond = /^[a-zA-Z ]*$/;
  //   if (!inputValues1.fname1.trim()) {
  //   errors.fname1 = "First name is required";
  //   } else if (!inputValues1.fname1.match(alphabetCond)) {
  //   errors.fname1 = "Please enter valid first name";
  //   } else {
  //   errors.fname1 = "";
  //   }
  //   //last validation
  //   if (!inputValues1.lname1.trim()) {
  //   errors.lname1 = "Last name is required";
  //   } else if (!inputValues1.lname1.match(alphabetCond)) {
  //   errors.lname1 = "Please enter valid last name";
  //   } else {
  //   errors.lname1 = "";
  //   }
  //   //number validation
  //   const numberCond = /^[0-9\b]+$/;
  //   if (!inputValues1.number1.trim()) {
  //   errors.number1 = "Number is required";
  //   } else if (!inputValues1.number1.match(numberCond)) {
  //   errors.number1 = "Please enter valid number";
  //   } else {
  //   errors.number1 = "";
  //   }
  //   // email validation
  //   const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //   if (!inputValues1.email1.trim()) {
  //     errors.email1 = "Email is required";
  //   } else if (!inputValues1.email1.match(emailCond)) {
  //     errors.email1 = "Please enter valid email address";
  //   } else {
  //     errors.email1 = "";
  //   }
  //   //address validation
  //   if (!inputValues1.address1.trim()) {
  //   errors.address1 = "Address is required";
  //   }else {
  //   errors.address1 = "";
  //   }
  //   //country validation
  //   if (!inputValues1.country1.trim()) {
  //     errors.country1 = "Country is required";
  //     }else {
  //     errors.country1 = "";
  //   }
  //   //state validation
  //   if (!inputValues1.state1.trim()) {
  //     errors.state1 = "State is required";
  //     }else {
  //     errors.state1 = "";
  //   }
  //   //city validation
  //   if (!inputValues1.city1.trim()) {
  //     errors.city1 = "City is required";
  //     }else {
  //     errors.city1 = "";
  //   }
  //   //zip validation
  //   if (!inputValues1.zip1.trim()) {
  //     errors.zip1 = "Zip is required";
  //     }else {
  //     errors.zip1 = "";
  //   }
  //   //company validation
  //   if (!inputValues1.company1.trim()) {
  //     errors.company1 = "Company is required";
  //     }else {
  //     errors.company1 = "";
  //   }

  //   setValidation1(errors);
  //   if(errors.fname1 === '' && errors.lname1 === '' && errors.number1 === '' && errors.email1 === '' && errors.address1 === '' && errors.country1 === '' && errors.state1 === ''&& errors.city1 === '' && errors.zip1 === '' && errors.company1 === ''){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // };
  const manage_address = (type, id) => {
    const shippingDetailsData = new FormData();
    shippingDetailsData.append('token', localStorage.getItem('token') );
    shippingDetailsData.append('id', id);
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/manage_address/${type}/${id}`, shippingDetailsData, params)
        .then(res => {
          if (res?.data?.status === 200) {
            getShippingDetails();
          }else {

          }
        })
        .catch(error => {
        });
  }
  const getShipping = (id) => {
    setIsLoading(true);
    const shippingDetailsData = new FormData();
    shippingDetailsData.append('token', localStorage.getItem('token') );
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/address/shipping/${id}`, shippingDetailsData, params)
        .then(res => {
          setIsLoading(false);
          if (res?.data?.status === 200) {
            const newData = {
              fname:  res?.data?.data?.first_name,
              lname: res?.data?.data?.last_name,
              number: res?.data?.data?.mobile_number,
              email: res?.data?.data?.email,
              address: res?.data?.data?.address,
              country: res?.data?.data?.country,
              state: res?.data?.data?.state,
              city: res?.data?.data?.city,
              zip: res?.data?.data?.postal_code,
              company: res?.data?.data?.company
            }
            setInputValue(newData);
          }else {

          }
        })
        .catch(error => {
          setIsLoading(false);
        });
  }
  const updateShippingAddress = (id) => {
    const isSuccess = checkValidation();
    if(isSuccess) {
      setIsLoadingButton(true);
      const shippingDetailsData = new FormData();
      shippingDetailsData.append('token', localStorage.getItem('token') );
      shippingDetailsData.append('first_name', inputValues.fname );
      shippingDetailsData.append('last_name', inputValues.lname );
      shippingDetailsData.append('email', inputValues.email );
      shippingDetailsData.append('address', inputValues.address );
      shippingDetailsData.append('country', inputValues.country );
      shippingDetailsData.append('mobile_number', inputValues.number );
      shippingDetailsData.append('state', inputValues.state );
      shippingDetailsData.append('city', inputValues.city );
      shippingDetailsData.append('postal_code', inputValues.zip );
      shippingDetailsData.append('company', inputValues.company );
      const params = {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*"
      };
      axios.post(`${appConstant.API_URL}/user/update_address/shipping/${id}`, shippingDetailsData, params)
      .then(res => {
          setIsLoadingButton(false);
          if (res?.data?.status === 200) {
              console.log(res?.data?.message);
          }else {

          }
      })
      .catch(error => {
          setIsLoadingButton(false);
      });
    }
  }
  const saveAddress = (e) => {
    e.preventDefault();
    const isSuccess = true; //checkValidation();
    let isShippingValidation = true;
    // if(!checked){
    //   isShippingValidation = checkValidation1();
    // }
    if(isSuccess && isShippingValidation) {
      orderCheckout();
    }
  }
  const orderSummaryData = () => {
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const cartData = new FormData();
    cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
    axios.post(`${appConstant.API_URL}/products/order_total`, cartData, params)
        .then(res => {
          if(res?.data?.status === 200){
            setOrderSummary(res?.data);
            setTotalPrice(res?.data?.total_price);
          }
        })
        .catch(error => {
        });
  }
  const orderCheckout = (token='') => {
    setIsLoadingButton(true);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    const shippingData = {...inputValues};
    const cartData = new FormData();
    cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
    cartData.append('shipping_data', JSON.stringify(shippingData));
    cartData.append('po', cartValues.po);
    cartData.append('order_notes', cartValues.orderNotes);
    cartData.append('payment_type', cartValues.payment);
    cartData.append('source_id', token);
    axios.post(`${appConstant.API_URL}/products/order_checkout`, cartData, params)
        .then(res => {
          setIsLoadingButton(false);
          if(res?.data?.status === 200){
            localStorage.setItem('cartTotal', 0);
            nav('/order-success/' + cartValues.payment);
          }else{
            nav('/order-failed');
          }
        })
        .catch(error => {
          setIsLoadingButton(false);
        });
  }
  const addShippingAddressApi = () => {
    setIsLoadingButton(true);
    const shippingDetailsData = new FormData();
    shippingDetailsData.append('token', localStorage.getItem('token') );
    shippingDetailsData.append('first_name', addInputValues.addFname );
    shippingDetailsData.append('last_name', addInputValues.addLname );
    shippingDetailsData.append('email', addInputValues.addEmail );
    shippingDetailsData.append('address', addInputValues.addAddress );
    shippingDetailsData.append('country', addInputValues.addCountry );
    shippingDetailsData.append('mobile_number', addInputValues.addNumber );
    shippingDetailsData.append('state', addInputValues.addState );
    shippingDetailsData.append('city', addInputValues.addCity );
    shippingDetailsData.append('postal_code', addInputValues.addZip );
    shippingDetailsData.append('company', addInputValues.addCompany );
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/update_address/shipping`, shippingDetailsData, params)
        .then(res => {
          setIsLoadingButton(false);
          if (res?.data?.status === 200) {
            console.log(res?.data?.message);
            const addData = {
              addFname: "",
              addLname: "",
              addNumber: "",
              addEmail: "",
              addAddress: "",
              addCountry: "",
              addState: "",
              addCity: "",
              addZip: "",
              addCompany: ""
            }
            setAddInputValue(addData);
            getShippingDetails();
          }else {

          }
        })
        .catch(error => {
          setIsLoadingButton(false);
        });
}
const [addInputValues, setAddInputValue] = useState({
  addFname: "",
  addLname: "",
  addNumber: "",
  addEmail: "",
  addAddress: "",
  addCountry: "",
  addState: "",
  addCity: "",
  addZip: "",
  addCompany: ""
});
function handleAddChange(e) {
  const { name, value } = e.target;
  setAddInputValue({ ...addInputValues, [name]: value });
}
const [addValidation, setAddValidation] = useState({
  addFname: "",
  addLname: "",
  addNumber: "",
  addEmail: "",
  addAddress: "",
  addCountry: "",
  addState: "",
  addCity: "",
  addZip: "",
  addCompany: ""
});
const checkAddValidation = () => {
  let errors = JSON.parse(JSON.stringify(addValidation));

  //first validation
  const alphabetCond = /^[a-zA-Z ]*$/;
  if (!addInputValues.addFname) {
  errors.addFname = "First name is required";
  } else if (!addInputValues.addFname.match(alphabetCond)) {
  errors.addFname = "Please enter valid first name";
  } else {
  errors.addFname = "";
  }
  //last validation
  if (!addInputValues.addLname) {
  errors.addLname = "Last name is required";
  } else if (!addInputValues.addLname.match(alphabetCond)) {
  errors.addLname = "Please enter valid last name";
  } else {
  errors.addLname = "";
  }
  //number validation
  const numberCond = /^[0-9\b]+$/;
  if (!addInputValues.addNumber) {
  errors.addNumber = "Number is required";
  } else if (!addInputValues.addNumber.match(numberCond)) {
  errors.addNumber = "Please enter valid number";
  } else {
  errors.addNumber = "";
  }
  // email validation
  const emailCond = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!addInputValues.addEmail) {
    errors.addEmail = "Email is required";
  } else if (!addInputValues.addEmail.match(emailCond)) {
    errors.addEmail = "Please enter valid email address";
  } else {
    errors.addEmail = "";
  }
  //address validation
  if (!addInputValues.addAddress) {
  errors.addAddress = "Address is required";
  }else {
  errors.addAddress = "";
  }
  //country validation
  if (!addInputValues.addCountry) {
    errors.addCountry = "Country is required";
    }else {
    errors.addCountry = "";
  }
  //state validation
  if (!addInputValues.addState) {
    errors.addState = "State is required";
    }else {
    errors.addState = "";
  }
  //city validation
  if (!addInputValues.addCity) {
    errors.addCity = "City is required";
    }else {
    errors.addCity = "";
  }
  //zip validation
  if (!addInputValues.addZip) {
    errors.addZip = "Zip is required";
    }else {
    errors.addZip = "";
  }
  //company validation
  if (!addInputValues.addCompany) {
    errors.addCompany = "Company is required";
    }else {
    errors.addCompany = "";
  }

  setAddValidation(errors);
  if(errors.addFname === '' && errors.addLname === '' && errors.addNumber === '' && errors.addEmail === '' && errors.addAddress === '' && errors.addCountry === '' && errors.addState === ''&& errors.addCity === '' && errors.addZip === '' && errors.addCompany === ''){
    return true;
  }else{
    return false;
  }
};
const addShippingAddress = () => {
  const isSuccess = checkAddValidation();
  if(isSuccess) {
    setIsLoadingButton(true);
    addShippingAddressApi();
  }
}
const getCards = () => {
  setIsLoading(true);
  const CardsData = new FormData();
  CardsData.append('token', localStorage.getItem('token') );
  const params = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  };
  axios.post(`${appConstant.API_URL}/cards/index`, CardsData, params)
      .then(res => {
        setIsLoading(false);
        if (res?.data?.status === 200) {
          setCards(res?.data?.data?.cards);
        }else {
          setCards([]);
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
}
  return (
    <>
    <Header/>
    <div className="cart-section page-spacing">
        <div className='breadcrumbs'>
            <Link to="/">Home / </Link> <Link to="/shop">Shop / </Link>  <Link to="/cart">Cart / </Link> <strong>Checkout</strong>
        </div>
        {/* <h6 className='heading mb-4'>You are checking out as guest. Have Account? <button type="button" className="links" data-bs-toggle="modal" data-bs-target="#login"> Login</button></h6> */}
        <div className='row'>
          <div className='col-lg-8 order-lg-1 order-2'>
          <h6 className='title me-5'>Billing Address</h6>
          <p className='mb-5'>
            <div style={{textTransform : 'capitalize', fontWeight : '700'}}>{user?.company}</div>
            <span style={{textTransform : 'capitalize'}}>{user?.first_name} {user?.last_name}</span>, {user?.address} {user?.city} {user?.state} {user?.country} {user?.postal_code}</p>
          {isLoading ? <LoadingSpinner/> :
            <>
            <h6 className='title me-5'>Shipping Address</h6>
            <button type="button" className='buttons mt-3' data-bs-toggle="modal" data-bs-target="#add-address">Add New Address</button>
            <div className='row mt-3'>
              {shippingData?.length > 0 && shippingData?.map((result, i) =>
              <>
                <div className='col-xl-6 col-sm-6 mb-4' key={'shipping'+i}>
                  <div className={result?.default_address === '0' ? 'card add-address' : 'card add-address default'}>
                    <div className='card-body position-relative p-4 pb-2'>
                        <p className='address'>
                          <div style={{textTransform : 'capitalize'}}>{result?.company}</div>
                          <span style={{textTransform : 'capitalize'}}>{result?.first_name} {result?.last_name}</span>, {result?.address} {result?.city} {result?.state} {result?.country} {result?.postal_code}
                        </p>
                        <div className='d-flex justify-content-between flex-wrap'>
                          {/* <button type="button" className='buttons mini-buttons mb-2' data-bs-toggle="modal" data-bs-target="#edit-address" onClick={() => {getShipping(result?.id)}}>Edit Address</button> */}
                          {result?.default_address === '0' && <button className='buttons mini-buttons mb-2' onClick={() => {manage_address('default', result?.id)}}>Use this address</button> }
                        </div>
                    </div>
                  </div>
                </div>
              </>
              )}
              </div>
              {/*
              {shippingData?.length > 0 &&
                  <select className='w-50 rounded-3 px-2 border-0 mx-auto my-3' name="selectAddress"
                          style={{backgroundColor: 'rgba(0, 0, 0, 0.10)'}}>
                    {shippingData?.length >0 && shippingData?.map((result, i) =>
                        <>
                    <option value={i} key={'shipping' + i}>{result?.first_name} {result?.last_name}, {result?.city}, {result?.state}, {result?.country}, {result?.postal_code} {result?.default_address === '1' ? ' [Default]' : ''}</option>
                        </>
                    )}
                  </select>
              }
              <div className='row'>
                <div className='col-sm-6'>
                  <label>First Name</label>
                  <input type="text" placeholder='Enter First Name' name="fname" value={inputValues.fname} onChange={(e) => handleChange(e)}/>
                  {validation.fname && <p className='formErrors'>{validation.fname}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Last Name</label>
                  <input type="text" placeholder='Enter Last Name' name="lname" value={inputValues.lname} onChange={(e) => handleChange(e)}/>
                  {validation.lname && <p className='formErrors'>{validation.lname}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Company</label>
                  <input type="text" placeholder='Enter Company Name' name="company" value={inputValues.company} onChange={(e) => handleChange(e)}/>
                    {validation.company && <p className='formErrors'>{validation.company}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Address</label>
                  <input type="text" placeholder='Enter Address' name="address" value={inputValues.address} onChange={(e) => handleChange(e)}/>
                    {validation.address && <p className='formErrors'>{validation.address}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Country</label>
                  <select name="country" value={inputValues.country} onChange={(e) => handleChange(e)}>
                      <option value="">Select Country</option>
                      <option value="USA">USA</option>
                  </select>
                  {validation.country && <p className='formErrors'>{validation.country}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>State</label>
                  <select name="state" value={inputValues.state} onChange={(e) => handleChange(e)}>
                      <option value="">Select State</option>
                    {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                        <option value={result} key={i}>{result}</option>
                    )}
                  </select>
                  {validation.state && <p className='formErrors'>{validation.state}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>City</label>
                  <input type="text" placeholder='Enter City' name="city" value={inputValues.city} onChange={(e) => handleChange(e)}/>
                    {validation.city && <p className='formErrors'>{validation.city}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Zip/Postal Code</label>
                  <input type="text" placeholder='Enter Zip/Postal Code' name="zip" value={inputValues.zip} onChange={(e) => handleChange(e)}/>
                    {validation.zip && <p className='formErrors'>{validation.zip}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Phone Number</label>
                  <input type="text" placeholder='Enter Phone Number' name="number" value={inputValues.number} onChange={(e) => handleChange(e)}/>
                    {validation.number && <p className='formErrors'>{validation.number}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Email Id</label>
                  <input type="text" placeholder='Enter Email Id' name="email" value={inputValues.email} onChange={(e) => handleChange(e)}/>
                    {validation.email && <p className='formErrors'>{validation.email}</p>}
                </div>
              </div> */}
              {/* <div className="form-check form-switch my-4">
                <input className="form-check-input mb-0" type="checkbox" placeholder='' name="hide-billing" checked={checked} onChange={e => isChecked(e)}/>
                <label className='ms-3 mb-0'>Billing Address is same as Shipping Address</label>
              </div> */}

              {/* {!checked ? <>
              <h6 className='title'>Billing Address</h6>
              <div className='row'>
                <div className='col-sm-6'>
                  <label>First Name</label>
                  <input type="text" placeholder='Enter First Name' name="fname1" value={inputValues1.fname1} onChange={(e) => handleChange(e)}/>
                  {validation1.fname1 && <p className='formErrors'>{validation1.fname1}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Last Name</label>
                  <input type="text" placeholder='Enter Last Name' name="lname1" value={inputValues1.lname1} onChange={(e) => handleChange(e)}/>
                  {validation1.lname1 && <p className='formErrors'>{validation1.lname1}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Company</label>
                  <input type="text" placeholder='Enter Company Name' name="company1" value={inputValues1.company1} onChange={(e) => handleChange(e)}/>
                    {validation1.company1 && <p className='formErrors'>{validation1.company1}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Address</label>
                  <input type="text" placeholder='Enter Address' name="address1" value={inputValues1.address1} onChange={(e) => handleChange(e)}/>
                    {validation1.address1 && <p className='formErrors'>{validation1.address1}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Country</label>
                  <select name="country1" value={inputValues1.country1} onChange={(e) => handleChange(e)}>
                      <option value="">Select Country</option>
                      <option value="1">1</option>
                  </select>
                  {validation1.country1 && <p className='formErrors'>{validation1.country1}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>State</label>
                  <select name="state1" value={inputValues1.state1} onChange={(e) => handleChange(e)}>
                      <option value="">Select State</option>
                      <option value="1">1</option>
                  </select>
                  {validation1.state1 && <p className='formErrors'>{validation1.state1}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>City</label>
                  <input type="text" placeholder='Enter City' name="city1" value={inputValues1.city1} onChange={(e) => handleChange(e)}/>
                    {validation1.city1 && <p className='formErrors'>{validation1.city1}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Zip/Postal Code</label>
                  <input type="text" placeholder='Enter Zip/Postal Code' name="zip1" value={inputValues1.zip1} onChange={(e) => handleChange(e)}/>
                    {validation1.zip1 && <p className='formErrors'>{validation1.zip1}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Phone Number</label>
                  <input type="text" placeholder='Enter Phone Number' name="number1" value={inputValues1.number1} onChange={(e) => handleChange(e)}/>
                    {validation1.number1 && <p className='formErrors'>{validation1.number1}</p>}
                </div>
                <div className='col-sm-6'>
                  <label>Email Id</label>
                  <input type="text" placeholder='Enter Email Id' name="email1" value={inputValues1.email1} onChange={(e) => handleChange(e)}/>
                    {validation1.email1 && <p className='formErrors'>{validation1.email1}</p>}
                </div>
              </div>
              </>
              : '' }             */}
              </>
            }

          </div>
          <div className='col-lg-4 order-lg-1 order-1 mb-4'>
            <div className='card'>
              <div className="card-body">
                <h6 className='title'>ORDER NOTES & SUMMARY</h6>
                <div className='order-detail'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <span>Total</span>	<strong>${orderSummary?.sub_total}</strong>
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    <span>Shipping Cost</span>	<strong>${orderSummary?.shipping_charges}</strong>
                    {/*<span>Free above $200</span>*/}
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    <span>Grand Total</span>	<strong>${orderSummary?.total_price}</strong>
                  </div>
                </div>
                <label>PO (optional)</label>
                <input type="text" placeholder='Enter PO' name="po" value={cartValues.po} onChange={(e) => cartHandleChange(e)}/>
                <p className='tagline'>Please write notes of your order</p>
                <textarea className='order-notes' placeholder='Order Notes' name="orderNotes" value={cartValues.orderNotes} onChange={(e) => cartHandleChange(e)}></textarea>
                <hr/>
                <h6 className='title'>Payment Methods</h6>
                <p className='tagline'>Please select a prefered payment method to use on this order.</p>
                <label className="payment-label">COD/Zelle
                  <input type="radio" checked={cartValues.payment === '0'} id="cash" name="payment" value="0" onChange={(e) => cartHandleChange(e)}/>
                  <span className="checkmark"></span>
                </label>
                  {(user?.is_net15 === '1') && (
                      <>
                      <label className="payment-label">Net15 <i style={{font: '12px'}}>(Must be Preapproved)</i>
                        <input type="radio" checked={cartValues.payment === '3'} id="cash1" name="payment" value="3" onChange={(e) => cartHandleChange(e)}/>
                        <span className="checkmark"></span>
                      </label>
                      </>
                      )}
                  {(user?.is_net30 === '1') && (
                      <>
                      <label className="payment-label">Net30 <i>(Must be Preapproved)</i>
                        <input type="radio" checked={cartValues.payment === '4'} id="cash2" name="payment" value="4" onChange={(e) => cartHandleChange(e)}/>
                        <span className="checkmark"></span>
                      </label>
                      </>
                  )}
                  {(cartValues.payment === '0' || cartValues.payment === '3' || cartValues.payment === '4') && <>
                  {isLoadingButton ? <div className='d-flex mt-4 justify-content-center'> <button type='button' className='buttons'>Proceed to Pay <img alt="loader" className='ms-2' src={`${appConstant.CDN_URL}/images/default.gif`} width={'16px'} height={'16px'}/></button></div> :
                      <div className='d-flex mt-4 justify-content-center'> <button type='button' className='buttons' onClick={saveAddress}>Proceed to Pay</button></div>
                  }
                  </>}
                <label className="payment-label">PayPal
                  <input type="radio" checked={cartValues.payment === '1'} id="paypal" name="payment" value="1" onChange={(e) => cartHandleChange(e)}/>
                  <span className="checkmark"></span>
                  {/*<PayPalButton*/}
                  {/*    createOrder={(data, actions) => createOrder(data, actions)}*/}
                  {/*    onApprove={(data, actions) => onApprove(data, actions)}*/}
                  {/*/>*/}
                  {cartValues.payment === '1' && <>
                  <PayPalScriptProvider options={{ "client-id": `${appConstant.PAYPAL_CLIENT_ID}` }}>
                    <PayPalButtons
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: totalPrice,
                                },
                              },
                            ],
                          });
                        }}
                        onApprove={(data, actions) => {
                          return actions.order.capture().then((details) => {
                            const name = details.payer.name.given_name;
                            orderCheckout();
                          });
                        }}
                    />
                  </PayPalScriptProvider>
                  </>}
                </label>
                <label className="payment-label">Credit Card
                  <input type="radio" checked={cartValues.payment === '2'} id="zelle" name="payment" value="2" onChange={(e) => cartHandleChange(e)}/>
                  <span className="checkmark"></span>
                </label>
                  <div style={{display: (cartValues.payment === '2') ? 'block' : 'none' }}>
                <form id="payment-form">
                  <div id="card-container"></div>
                  {isLoadingButton ? <div className='d-flex mt-4 justify-content-center'> <button type='button' className='buttons'>Proceed to Pay <img alt="loader" className='ms-2' src={`${appConstant.CDN_URL}/images/default.gif`} width={'16px'} height={'16px'}/></button></div> :
                      <div className='d-flex mt-4 justify-content-center'> <button type='button' id="card-button" className='buttons'>Proceed to Pay</button></div>
                  }
                </form>
                <div id="payment-status-container"></div>
                  </div>

                  <label className="payment-label">Saved Card
                    <input type="radio" checked={cartValues.payment === '5'} id="savedCard" name="payment" value="5" onChange={(e) => cartHandleChange(e)}/>
                    <span className="checkmark"></span>
                  </label>
                  <div style={{display: (cartValues.payment === '5') ? 'block' : 'none' }}>
                  <ul className="list-unstyled">
                      {cards?.length > 0 && cards?.map((result, i) =>
                      <li className='mb-2'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div style={{width : '20px',marginRight : '10px', height:'35px'}}>
                            <input className='d-inline mb-0' type="radio" id={"card" + i} checked={cartValues.savedCard === result?.id} name="savedCard" value={result?.id} onChange={(e) => cartHandleChange(e, result?.customer_id)}/>
                          </div>
                          <div className='w-100'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <h6 className='mb-0'>xxxx-xxxx-xxxx-{result?.last_4}</h6>
                              <img src='/images/credit-card.png' style={{width:'40px'}} alt='cards'/>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                              <p className='mb-0'>{result?.card_type} ({result?.card_brand})</p>
                              <small className='mb-0'>{result?.exp_month}/{result?.exp_year}</small>
                            </div>
                          </div>
                        </div>
                      </li>
                      )}
                    </ul> 
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <Footer/>
    {/* edit-address popup  */}
        <div className="modal back-shadow" id="edit-address">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                    <div className="modal-body p-4">
                    {isLoading ? <LoadingSpinner/> :
                      <form onSubmit={updateShippingAddress}>
                        <div className='row'>
                          <div className='col-sm-12'>
                            <label>First Name</label>
                            <input type="text" placeholder="First Name" name="fname" value={inputValues.fname} onChange={(e) => handleChange(e)}/>
                            {validation.fname && <p className='formErrors'>{validation.fname}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Last Name</label>
                            <input type="text" placeholder="Last Name" name="lname" value={inputValues.lname} onChange={(e) => handleChange(e)}/>
                            {validation.lname && <p className='formErrors'>{validation.lname}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Mobile Number</label>
                            <input type="text" placeholder="Mobile Number" name="number" value={inputValues.number} onChange={(e) => handleChange(e)}/>
                            {validation.number && <p className='formErrors'>{validation.number}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Email</label>
                            <input type="text" placeholder="Email" name="email" value={inputValues.email} onChange={(e) => handleChange(e)}/>
                            {validation.email && <p className='formErrors'>{validation.email}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Address</label>
                            <input type='text' name='address' placeholder='Address' value={inputValues.address} onChange={(e) => handleChange(e)}/>
                            {validation.address && <p className='formErrors'>{validation.address}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Country</label>
                            <select name="country"  value={inputValues.country} onChange={(e) => handleChange(e)}>
                                <option value="">Select Country</option>
                                <option value="USA">USA</option>
                            </select>
                            {validation.country && <p className='formErrors'>{validation.country}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>State</label>
                            <select name="state" value={inputValues.state} onChange={(e) => handleChange(e)}>
                                <option value="">Select State</option>
                              {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                                  <option value={result} key={i}>{result}</option>
                              )}
                            </select>
                            {validation.state && <p className='formErrors'>{validation.state}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>City</label>
                            <input type="text" placeholder='Enter City' name='city' value={inputValues.city} onChange={(e) => handleChange(e)}/>
                            {validation.city && <p className='formErrors'>{validation.city}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Zip/Postal Code</label>
                            <input type="text" placeholder='Enter Zip/Postal Code' name='zip'  value={inputValues.zip} onChange={(e) => handleChange(e)}/>
                            {validation.zip && <p className='formErrors'>{validation.zip}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Company</label>
                            <input type="text" placeholder='Enter Company Name' name='company'  value={inputValues.company} onChange={(e) => handleChange(e)}/>
                            {validation.company && <p className='formErrors'>{validation.company}</p>}
                          </div>
                        </div>
                        {isLoadingButton ? <div className='text-center mt-3'><button type='submit' className="buttons">Update <img alt="loader" className='ms-2' src={`${appConstant.CDN_URL}/images/default.gif`} width={'16px'} height={'16px'}/></button></div> :
                          <div className='text-center mt-3'><button type='submit' className="buttons">Update</button></div>
                        }
                      </form>
                    }
                    </div>
                </div>
            </div>
        </div>
        {/* add-address popup  */}
        <div className="modal back-shadow" id="add-address">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                    <div className="modal-body p-4">
                      <form>
                        <div className='row'>
                          <div className='col-sm-12'>
                            <label>First Name</label>
                            <input type="text" placeholder="First Name" name="addFname" value={addInputValues.addFname} onChange={(e) => handleAddChange(e)}/>
                            {addValidation.addFname && <p className='formErrors'>{addValidation.addFname}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Last Name</label>
                            <input type="text" placeholder="Last Name" name="addLname" value={addInputValues.addLname} onChange={(e) => handleAddChange(e)}/>
                            {addValidation.addLname && <p className='formErrors'>{addValidation.addLname}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Mobile Number</label>
                            <input type="text" placeholder="Mobile Number" name="addNumber" value={addInputValues.addNumber} onChange={(e) => handleAddChange(e)}/>
                            {addValidation.addNumber && <p className='formErrors'>{addValidation.addNumber}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Email</label>
                            <input type="text" placeholder="Email" name="addEmail" value={addInputValues.addEmail} onChange={(e) => handleAddChange(e)}/>
                            {addValidation.addEmail && <p className='formErrors'>{addValidation.addEmail}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Address</label>
                            <input type='text' name='addAddress' placeholder='Address' value={addInputValues.addAddress} onChange={(e) => handleAddChange(e)}/>
                            {addValidation.addAddress && <p className='formErrors'>{addValidation.addAddress}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Country</label>
                            <select name="addCountry"  value={addInputValues.addCountry} onChange={(e) => handleAddChange(e)}>
                                <option value="">Select Country</option>
                                <option value="USA">USA</option>
                            </select>
                            {addValidation.addCountry && <p className='formErrors'>{addValidation.addCountry}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>State</label>
                            <select name="addState" value={addInputValues.addState} onChange={(e) => handleAddChange(e)}>
                                <option value="">Select State</option>
                              {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                                  <option value={result} key={i}>{result}</option>
                              )}
                            </select>
                            {addValidation.addState && <p className='formErrors'>{addValidation.addState}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>City</label>
                            <input type="text" placeholder='Enter City' name='addCity' value={addInputValues.addCity} onChange={(e) => handleAddChange(e)}/>
                            {addValidation.addCity && <p className='formErrors'>{addValidation.addCity}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Zip/Postal Code</label>
                            <input type="text" placeholder='Enter Zip/Postal Code' name='addZip'  value={addInputValues.addZip} onChange={(e) => handleAddChange(e)}/>
                            {addValidation.addZip && <p className='formErrors'>{addValidation.addZip}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Company</label>
                            <input type="text" placeholder='Enter Company Name' name='addCompany'  value={addInputValues.addCompany} onChange={(e) => handleAddChange(e)}/>
                            {addValidation.addCompany && <p className='formErrors'>{addValidation.addCompany}</p>}
                          </div>
                        </div>
                        {isLoadingButton ? <div className='text-center mt-3'><button type='button' className="buttons">Add Shipping Address <img alt="loader" className='ms-2' src={`${appConstant.CDN_URL}/images/default.gif`} width={'16px'} height={'16px'}/></button></div> :
                          <div className='text-center mt-3'><button type='button' className="buttons" onClick={() => {addShippingAddress()}}>Add Shipping Address</button></div>
                        }
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
};

export default Checkout;
