import React from 'react';

function LoadingSpinner() {
    return (
        <div className="spinner-container">
            {/* <img src={`${appConstant.CDN_URL}/images/default.gif`} width={'100px'} height={'100px'}/> */}
            <span className="loader"></span>
        </div>
    );
}
export default LoadingSpinner;
