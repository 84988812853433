import React, {useState, useEffect} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link,useSearchParams } from 'react-router-dom';
import MetaTags from "react-meta-tags";
import BlogsCard from './shared/blogsCard/BlogsCard';
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import Pagination from './shared/Pagination';
import LoadingSpinner from './shared/Loader';

function Blogs() {
  let [searchParams] = useSearchParams();
  let [searchInput, setSearchinput] = useState(''); 
  const [isLoading, setIsLoading] = useState(true);
  const [blogs, setBlogs] = React.useState([]);
  useEffect(() => {
    blogsData(1);
  }, []);

  const handelInput = (e) => {
    setSearchinput(e.target.value);
  }
  const blogsData = (page) => {
    setIsLoading(true);
    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
    const blogs = new FormData();
    let search = '';
    if(searchParams?.get('blog')){
      search = searchParams?.get('blog');
      setSearchinput(searchParams?.get('blog'));
    }
    blogs.append('search', search);
    axios.post(`${appConstant.API_URL}/blogs/${page}`, blogs, params)
        .then(res => {
            setIsLoading(false);
            setBlogs(res?.data?.data);
        })
        .catch(error => {
            setIsLoading(false);
        });
}
  return (
    <>
      <MetaTags>
        <title>Blogs - S & S Fashions Inc.</title>
        <meta name="description" content={"S & S Fashions Inc. Blogs"} />
        <meta property="og:title" content={'Blogs - S & S Fashions Inc.'} />
      </MetaTags>
        {/* header  */}
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content contact-section mb-4'> 
          <div className='page-spacing'>      
            <div className='breadcrumbs'>
                <Link to="/">Home / </Link> <strong>Blogs</strong>
            </div>
            
            <div className='header-section d-block d-md-flex justify-content-between align-items-center border-0 mb-4'>
              <h6 className='heading'>Blogs</h6>
              <form action="/blogs">
              <div className="search-box  m-auto me-md-0">
                <input className="search-input" type="text" placeholder="Search..." name="blog" value={searchInput} onChange={(e) => handelInput(e)}/>
                <button type="submit" className="search"><img src={`${appConstant.CDN_URL}/images/search-black.webp`} alt="search"/></button>
              </div>
              </form>
            </div>
            {isLoading ? <LoadingSpinner/> :
              <div className='blogsColumn1'>
                {searchParams?.get('blog') && <h4 className='title'>Search result for: {searchParams?.get('blog')}</h4>}
                    <div className='card-container'>                     
                    {blogs?.data?.length > 0  && blogs?.data?.map((result, i) =>
                      <BlogsCard key={'blogs' + i} image={result?.blog_image} slug={result?.blog_slug} title={result?.blog_title} description={result?.short_description} posted={result?.posted_on} author={result?.author}/>   
                    )}
                    {!blogs?.data?.length && <h2>Not Found. </h2>}                                 
                    </div>  
                    {blogs?.data?.length > 0 &&
                        <Pagination
                            className="pagination justify-content-center mt-5"
                            currentPage={blogs?.current_page}
                            totalCount={blogs?.total_pages}
                            pageSize={1}
                            onPageChange={page => blogsData(page)}
                        />
                    }                  
                </div>
                 }
            </div>
        </div>       
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default Blogs;