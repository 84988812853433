import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Sidebar from './shared/Sidebar';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
function AddCard() {
    const nav = useNavigate();
    // Sqaure up payment gateway
    const appId = 'sq0idp-NV6yd_59RLHtxGFAJvuA-A';
    //const appId = 'sandbox-sq0idb-kJfOVbTKZYfrMcUyVzmaLA';
    const locationId = 'USA';

    async function initializeCard(payments) {
        const card = await payments.card();
        await card.attach('#card-container');

        return card;
    }

    async function createPayment(token) {
        // const body = JSON.stringify({
        //   locationId,
        //   sourceId: token,
        // });
        //
        // const paymentResponse = await fetch('/payment', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body,
        // });
        return addcard(token);
        // if (paymentResponse.ok) {
        //   return paymentResponse.json();
        // }
        //
        // const errorBody = await paymentResponse.text();
        // throw new Error(errorBody);
    }

    const addcard = (token='') => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        const cartData = new FormData();
        cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        cartData.append('source_id', token);
        axios.post(`${appConstant.API_URL}/cards/add_card`, cartData, params)
            .then(res => {
                if(res?.data?.status === 200){
                    nav('/manage-saved-cards/');
                }
            })
            .catch(error => {
            });
    }

    async function tokenize(paymentMethod) {
        const tokenResult = await paymentMethod.tokenize();
        console.log('token result', tokenResult);
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }

            throw new Error(errorMessage);
        }
    }

    // status is either SUCCESS or FAILURE;
    function displayPaymentResults(status) {
        const statusContainer = document.getElementById(
            'payment-status-container'
        );
        if (status === 'SUCCESS') {
            statusContainer.classList.remove('is-failure');
            statusContainer.classList.add('is-success');
        } else {
            statusContainer.classList.remove('is-success');
            statusContainer.classList.add('is-failure');
        }

        statusContainer.style.visibility = 'visible';
    }

    document.addEventListener('DOMContentLoaded', async function () {
        if (!window.Square) {
            throw new Error('Square.js failed to load properly');
        }

        let payments;
        try {
            payments = window.Square.payments(appId, locationId);
        } catch {
            const statusContainer = document.getElementById(
                'payment-status-container'
            );
            statusContainer.className = 'missing-credentials';
            statusContainer.style.visibility = 'visible';
            return;
        }

        let card;
        try {
            card = await initializeCard(payments);
        } catch (e) {
            console.error('Initializing Card failed', e);
            return;
        }

        // Checkpoint 2.
        async function handlePaymentMethodSubmission(event, paymentMethod) {
            event.preventDefault();

            try {
                // disable the submit button as we await tokenization and make a payment request.
                cardButton.disabled = true;
                const token = await tokenize(paymentMethod);
                const paymentResults = await createPayment(token);
                displayPaymentResults('SUCCESS');

                console.debug('Payment Success', paymentResults);
            } catch (e) {
                cardButton.disabled = false;
                displayPaymentResults('FAILURE');
                console.error(e.message);
            }
        }

        const cardButton = document.getElementById('card-button');
        cardButton.addEventListener('click', async function (event) {
            await handlePaymentMethodSubmission(event, card);
        });
    });
    // Square up payment gateway end
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='cart-section page-spacing'>
          <div className='breadcrumbs'>
              <Link to="/">Home / </Link> <strong>Add New Card</strong>
          </div>
          <div className='row'>
            <div className='col-md-3'>
                <div className='card'>
                  <div className="card-body">
                      <h6 className='title'>MY ACCOUNT</h6>
                      <Sidebar id='4'/>
                  </div>
                </div>
            </div>
            <div className='col-md-9'>
                <h6 className='title mb-4'>Add New Card</h6>
                <form id="payment-form">
                    <div id="card-container"></div>
                    <div className='d-flex mt-4 justify-content-center'> <button type='button' id="card-button" className='buttons'>Add Card</button></div>
                </form>
                <div id="payment-status-container"></div>
            </div>
          </div>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default AddCard;