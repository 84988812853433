import React, {useEffect, useState} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Sidebar from './shared/Sidebar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import LoadingSpinner from './shared/Loader';

function Billing() {
  useEffect(() => {
    getBillingDetails();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [inputValues, setInputValue] = useState({
    fname: "",
    lname: "",    
    number: "",
    email: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    company: "",
  });
  function handleChange(e) {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  const [validation, setValidation] = useState({
    fname: "",
    lname: "",    
    number: "",
    email: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    company: "",
  });
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    //first validation
    const alphabetCond = /^[a-zA-Z ]*$/;
    if (!inputValues.fname.trim()) {
    errors.fname = "First name is required";
    } else if (!inputValues.fname.match(alphabetCond)) {
    errors.fname = "Please enter valid first name";
    } else {
    errors.fname = "";
    }
    //last validation
    if (!inputValues.lname.trim()) {
    errors.lname = "Last name is required";
    } else if (!inputValues.lname.match(alphabetCond)) {
    errors.lname = "Please enter valid last name";
    } else {
    errors.lname = "";
    }
    //number validation
    const numberCond = /^[0-9\b]+$/;
    if (!inputValues.number.trim()) {
    errors.number = "Number is required";
    } else if (!inputValues.number.match(numberCond)) {
    errors.number = "Please enter valid number";
    } else {
    errors.number = "";
    }
    // email validation
    const emailCond = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!inputValues.email.trim()) {
      errors.email = "Email is required";
    } else if (!inputValues.email.match(emailCond)) {
      errors.email = "Please enter valid email address";
    } else {
      errors.email = "";
    }    
    //address validation
    if (!inputValues.address.trim()) {
    errors.address = "Address is required";
    }else {
    errors.address = "";
    }
    //country validation
    if (!inputValues.country.trim()) {
      errors.country = "Country is required";
      }else {
      errors.country = "";
    }
    //state validation
    if (!inputValues.state.trim()) {
      errors.state = "State is required";
      }else {
      errors.state = "";
    }
    //city validation
    if (!inputValues.city.trim()) {
      errors.city = "City is required";
      }else {
      errors.city = "";
    }
    //zip validation
    if (!inputValues.zip.trim()) {
      errors.zip = "Zip is required";
      }else {
      errors.zip = "";
    }
    //company validation
    if (!inputValues.company.trim()) {
      errors.company = "Company is required";
      }else {
      errors.company = "";
    }

    setValidation(errors);
    if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === '' && errors.address === '' && errors.country === '' && errors.state === ''&& errors.city === '' && errors.zip === '' && errors.company === ''){
      return true;
    }else{
      return false;
    }
  };
  const updateBillingAddress = (e) => {
    e.preventDefault();
    const isSuccess = checkValidation();
    if(isSuccess) {
      setIsLoadingButton(true);
      const billingDetailsData = new FormData();
      billingDetailsData.append('token', localStorage.getItem('token') );
      billingDetailsData.append('first_name', inputValues.fname );
      billingDetailsData.append('last_name', inputValues.lname );
      billingDetailsData.append('email', inputValues.email );
      billingDetailsData.append('address', inputValues.address );
      billingDetailsData.append('country', inputValues.country );
      billingDetailsData.append('mobile_number', inputValues.number );
      billingDetailsData.append('state', inputValues.state );
      billingDetailsData.append('city', inputValues.city );
      billingDetailsData.append('postal_code', inputValues.zip );
      billingDetailsData.append('company', inputValues.company );
      const params = {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*"
      };
      axios.post(`${appConstant.API_URL}/user/update_address/billing`, billingDetailsData, params)
      .then(res => {
          setIsLoadingButton(false);
          if (res?.data?.status === 200) {
              console.log(res?.data?.message);
          }else {

          }
      })
      .catch(error => {
          setIsLoadingButton(false);
      });
    }        
  } 
  const getBillingDetails = () => {
    setIsLoading(true);
    const billingDetailsData = new FormData();
    billingDetailsData.append('token', localStorage.getItem('token') );
    const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/address/billing`, billingDetailsData, params)
          .then(res => {
              setIsLoading(false);
              if (res?.data?.status === 200) {
                  const newData = {
                    fname:  res?.data?.data?.first_name,
                    lname: res?.data?.data?.last_name,    
                    number: res?.data?.data?.mobile_number,
                    email: res?.data?.data?.email,
                    address: res?.data?.data?.address,
                    country: res?.data?.data?.country,
                    state: res?.data?.data?.state,
                    city: res?.data?.data?.city,
                    zip: res?.data?.data?.postal_code,
                    company: res?.data?.data?.company
                  }
                  setInputValue(newData);
              }else {
                
              }
          })
          .catch(error => {
              setIsLoading(false);
          });
  }
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='cart-section page-spacing'>
          <div className='breadcrumbs'>
              <Link to="/">Home / </Link> <strong>Billing</strong>
          </div>
          <div className='row'>
            <div className='col-md-3'>
                <div className='card'>
                <div className="card-body">
                    <h6 className='title'>MY ACCOUNT</h6>
                    <Sidebar id='4'/>
                </div>
                </div>
            </div>
            <div className='col-md-9'>
            <h6 className='title mb-4'>Billing Address</h6>
            {isLoading ? <LoadingSpinner/> :
              <form onSubmit={updateBillingAddress}>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>First Name</label>
                    <input type="text" placeholder="First Name" name="fname" value={inputValues.fname} onChange={(e) => handleChange(e)}/>
                    {validation.fname && <p className='formErrors'>{validation.fname}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Last Name</label>
                    <input type="text" placeholder="Last Name" name="lname" value={inputValues.lname} onChange={(e) => handleChange(e)}/>
                    {validation.lname && <p className='formErrors'>{validation.lname}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Mobile Number</label>
                    <input type="text" placeholder="Mobile Number" name="number" value={inputValues.number} onChange={(e) => handleChange(e)}/>
                    {validation.number && <p className='formErrors'>{validation.number}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Email</label>
                    <input type="text" placeholder="Email" name="email" value={inputValues.email} onChange={(e) => handleChange(e)}/>
                    {validation.email && <p className='formErrors'>{validation.email}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Address</label>
                    <input type='text' name='address' placeholder='Address' value={inputValues.address} onChange={(e) => handleChange(e)}/>
                    {validation.address && <p className='formErrors'>{validation.address}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Country</label>
                    <select name="country"  value={inputValues.country} onChange={(e) => handleChange(e)}>
                        <option value="">Select Country</option>
                        <option value="USA">USA</option>
                    </select>      
                    {validation.country && <p className='formErrors'>{validation.country}</p>}          
                  </div>
                  <div className='col-sm-6'>
                    <label>State</label>
                    <select name="state" value={inputValues.state} onChange={(e) => handleChange(e)}>
                        <option value="">Select State</option>
                      {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                          <option value={result} key={i}>{result}</option>
                      )}
                    </select>  
                    {validation.state && <p className='formErrors'>{validation.state}</p>}             
                  </div>
                  <div className='col-sm-6'>
                    <label>City</label>
                    <input type="text" placeholder='Enter City' name='city' value={inputValues.city} onChange={(e) => handleChange(e)}/>  
                    {validation.city && <p className='formErrors'>{validation.city}</p>}            
                  </div>
                  <div className='col-sm-6'>
                    <label>Zip/Postal Code</label>
                    <input type="text" placeholder='Enter Zip/Postal Code' name='zip'  value={inputValues.zip} onChange={(e) => handleChange(e)}/> 
                    {validation.zip && <p className='formErrors'>{validation.zip}</p>}             
                  </div>
                  <div className='col-sm-6'>
                    <label>Company</label>
                    <input type="text" placeholder='Enter Company Name' name='company'  value={inputValues.company} onChange={(e) => handleChange(e)}/> 
                    {validation.company && <p className='formErrors'>{validation.company}</p>}               
                  </div>
                </div>
                {isLoadingButton ? <div className='text-center mt-3'><button type='submit' className="buttons">Update <img alt="loader" className='ms-2' src={`${appConstant.CDN_URL}/images/default.gif`} width={'16px'} height={'16px'}/></button></div> :
                  <div className='text-center mt-3'><button type='submit' className="buttons">Update</button></div>
                }            
              </form>
              }
               <div className='row mt-3'>
                  <div className='col-lg-4 col-sm-6 mb-4'>
                    <div className='card add-address default'>
                      <div className='card-body position-relative p-4 pb-2'>
                          <button type="button" className="position-absolute remove-address">&times;</button>
                          <p className='address'>
                          NEIL MANGAL
                          ATTN: NEIL MANGAL
                          941 LONGFELLOW AVE
                          BRONX, NY 10474
                          </p>
                          <div className='d-flex justify-content-between flex-wrap'>
                            <button className='buttons mini-buttons mb-2'>Edit Address</button>
                            <button className='buttons mini-buttons mb-2'>Make Default</button>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-sm-6 mb-4'>
                    <div className='card add-address'>
                      <div className='card-body position-relative p-4 pb-2'>
                          <button type="button" className="position-absolute remove-address">&times;</button>
                          <p className='address'>
                          NEIL MANGAL
                          ATTN: NEIL MANGAL
                          941 LONGFELLOW AVE
                          BRONX, NY 10474
                          </p>
                          <div className='d-flex justify-content-between flex-wrap'>
                            <button className='buttons mini-buttons mb-2'>Edit Address</button>
                            <button className='buttons mini-buttons mb-2'>Make Default</button>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-sm-6 mb-4'>
                    <div className='card add-address'>
                      <div className='card-body position-relative p-4 pb-2'>
                          <button type="button" className="position-absolute remove-address">&times;</button>
                          <p className='address'>
                          NEIL MANGAL
                          ATTN: NEIL MANGAL
                          941 LONGFELLOW AVE
                          BRONX, NY 10474
                          </p>
                          <div className='d-flex justify-content-between flex-wrap'>
                            <button className='buttons mini-buttons mb-2'>Edit Address</button>
                            <button className='buttons mini-buttons mb-2'>Make Default</button>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-sm-6 mb-4'>
                    <div className='card add-address'>
                      <div className='card-body position-relative p-4 pb-2'>
                          <button type="button" className="position-absolute remove-address">&times;</button>
                          <p className='address'>
                          NEIL MANGAL
                          ATTN: NEIL MANGAL
                          941 LONGFELLOW AVE
                          BRONX, NY 10474
                          </p>
                          <div className='d-flex justify-content-between flex-wrap'>
                            <button className='buttons mini-buttons mb-2'>Edit Address</button>
                            <button className='buttons mini-buttons mb-2'>Make Default</button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>          
          </div>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default Billing;