import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link, useParams } from 'react-router-dom';
import appConstant from './shared/constant/constant.json';

function OrderSuccess() {
  const props = useParams();
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content page-spacing mb-4'>          
          <div className='breadcrumbs'>
            <Link to="/">Home / </Link><Link to="/orders">Orders / </Link> <strong>Order Success</strong>
          </div>
          <div className='order-status'>
            <div className='row d-flex align-items-center'>
                <div className='col-md-6 mb-4'>
                  <img className='order-success' src={`${appConstant.CDN_URL}/images/order-success.webp`} alt='order-success'/>
                </div>
                <div className='col-md-6'>
                  <h6 className='heading'>Order Confirmed</h6>
                  <p className='content mb-4'> Thank you for shopping at S&S Fashions, Inc. A performa invoice has been emailed to you. An final invoice will be emailed soon after allocations are complete. Please shop with us again. </p>  
                  {props && props?.type === '0' &&  <> <p> Alternatively to using cash on pickup you can pay by zelle using the QR code below, or by sending the full amount to sandsfashions@aol.com.</p><img src={`${appConstant.CDN_URL}/images/qrcode.webp`} /> </>} <br/>
                  <Link to='/shop' className="buttons mt-4">&#129052; Back to Shopping</Link>
                </div>
            </div>            
          </div>          
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default OrderSuccess;