import React, {useState, useEffect} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link, useParams } from 'react-router-dom';
import MetaTags from "react-meta-tags";
import LoadingSpinner from './shared/Loader';
import axios from "axios";
import appConstant from "./shared/constant/constant.json";

function BlogsDetailPage() {
  const [isLoadingBlogs, setIsLoadingBlogs] = useState(true);
    const props = useParams();
    const [blogs, setBlogs] = React.useState([]);
    const [blog, setBlog] = useState({});
    useEffect(() => {
      blogsData(1);
      getBlogData();
    }, []);
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    const blogsData = (page) => {
      setIsLoadingBlogs(true);
      const blogs = new FormData();
      axios.post(`${appConstant.API_URL}/blogs/${page}`, blogs, params)
          .then(res => {
              setIsLoadingBlogs(false);
              setBlogs(res?.data?.data);
          })
          .catch(error => {
              setIsLoadingBlogs(false);
          });
  }
    const getBlogData = () => {
        axios.get(`${appConstant.API_URL}/blogs/s/${props.slug}`, params)
            .then(res => { 
                setIsLoadingBlogs(false);
                if(res?.data?.status === '200'){
                    setBlog(res?.data?.data);
                    console.log(res?.data?.data, 'manish')
                }else{
                    setBlog({});
                }
            })
            .catch(error => {
                setIsLoadingBlogs(true);
            });
    }
  return (
    <>
      <MetaTags>
        <title>{blog?.blog_title}  | S & S Fashions Inc.</title>
        <meta name="description" content={blog?.short_description} />
        <meta property="og:title" content={blog?.blog_title} />
        <meta property="og:image" content={blog?.blog_image } />
      </MetaTags>
        {/* header  */}
        <Header/>
        <div className='static-page-banner'></div>
        <div className='static-page-content contact-section mb-4'> 
          <div className='page-spacing'>      
            <div className='breadcrumbs'>
                <Link to="/">Home / </Link><Link to="/blogs">Blogs / </Link> <strong>{blog?.blog_title}</strong>
            </div>
            {isLoadingBlogs ? (
                        <LoadingSpinner />
                        ) : (
                        <>
            <div className='blogDetailPage blogsPage'>
                <div className='blogsColumn1'>
                    <img className='detail-image' src={blog?.blog_image }alt={blog?.blog_title}/>
                    <h1 className='title'>{blog?.blog_title}</h1>
                    <h6 className='blog-posted mb-4'>Author - {blog?.author}, Posted - {blog?.posted_on}</h6>                                    
                    <div className="content" dangerouslySetInnerHTML={{__html:blog?.blog_description}}></div>             
                </div>
                <div className='blogsColumn2'>
                    <div className='recent-box'>
                        <h3 className='heading'>Recent Blogs</h3>
                          <form action='/blogs' method='get'>
                            <div className='header-section d-block d-md-flex border-0 mb-4'>
                              <div className="search-box  m-auto me-lg-0">
                                <input className="search-input" type="text" placeholder="Search..." name="blog"/>
                                <button type="submit" className="search"><img src={`${appConstant.CDN_URL}/images/search-black.webp`} alt="search"/></button>
                              </div>                            
                            </div>
                          </form>
                          {blogs?.data?.length > 0  && blogs?.data?.map((result, i) =>
                          <a key={'blogs' + i} href={'/blogs/' + result?.blog_slug} className='recent-blogs'>
                            <div className='recent-column1'>
                                <img className="blog-img" src={result?.blog_image} alt="title"/>
                            </div>
                            <div className='recent-column2'>
                                <h6 className='blog-title'>{result?.blog_title}</h6>
                                <div className='blog-content'>{result?.short_description}</div>
                            </div>
                          </a>  
                           )}  
                    </div>          
                </div>
            </div>
            </>
            )}
            </div>
        </div>       
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default BlogsDetailPage;