import React, {useEffect, useState} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Sidebar from './shared/Sidebar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
function ManageCards() {
    useEffect(() => {
        getCards();
    }, []);
    const [cards, setCards] = useState([]);
    const getCards = () => {
        const CardsData = new FormData();
        CardsData.append('token', localStorage.getItem('token') );
        const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        axios.post(`${appConstant.API_URL}/cards/index`, CardsData, params)
            .then(res => {
                if (res?.data?.status === 200) {
                    setCards(res?.data?.data?.cards);
                }else {
                    setCards([]);
                }
            })
            .catch(error => {
            });
    }
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='cart-section page-spacing'>
          <div className='breadcrumbs'>
              <Link to="/">Home / </Link> <strong>Manage Saved Cards</strong>
          </div>
          <div className='row'>
            <div className='col-md-3'>
                <div className='card'>
                  <div className="card-body">
                      <h6 className='title'>MY ACCOUNT</h6>
                      <Sidebar id='4'/>
                  </div>
                </div>
            </div>
            <div className='col-md-9'>
                <div className='d-flex justify-content-between mb-3'>
                    <h6 className='title mb-4'>Manage Saved Cards</h6> <a href='/add-new-card' className='action-btn'><img alt='edit' src='/images/plus.webp'/> Add New Card</a>
                </div>
              <table className='table table-bordered text-start table-striped'>
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Card Number</th>
                        <th>Brand</th>
                        <th>Card Type</th>
                        <th>Expiry Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {cards?.length > 0 && cards?.map((result, i) =>
                    <>
                    <tr key={'cards' + i}>
                        <td>{i+1}</td>
                        <td><h6 className='mb-0'>xxxx-xxxx-xxxx-{result?.last_4}</h6></td>
                        <td><p className='mb-0'>{result?.card_brand}</p></td>
                        <td><p className='mb-0'>{result?.card_type}</p></td>
                        <td><p className='mb-0'>{result?.exp_month}/{result?.exp_year}</p></td>
                        <td>
                            <button type='button' className='action-btn'><img alt='trash' src='/images/trash.webp' /></button>
                        </td>
                    </tr>
                    </>
                )}
                </tbody>
              </table>

            </div>
          </div>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default ManageCards;
