import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import Header from './shared/Header';
import Footer from './shared/Footer';
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import LoadingSpinner from './shared/Loader';
import Pagination from './shared/Pagination';
import MetaTags from "react-meta-tags";
function Shop() {
    let [searchParams, setSearchParams] = useSearchParams();
    const nav = useNavigate();
    const ref = useRef();
    const [product, setProduct] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        products(1);
    }, []);

    const products = (page) => {
        setIsLoading(true);
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        let search = searchParams?.get('s') ? searchParams?.get('s') : '';
        const productData = new FormData();
        productData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        productData.append('search', search);
        axios.post(`${appConstant.API_URL}/products/${page}`, productData, params)
            .then(res => {
                setIsLoading(false);
                setProduct(res?.data?.data);
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
  return (
    <>
        <MetaTags>
            <title>Collection - S & S Fashions Inc.</title>
            <meta name="description" content={"S & S Fashions Inc. Collection"} />
            <meta property="og:title" content={'Collection - S & S Fashions Inc.'} />
            <meta property="og:image" content={'/images/time-bg.webp'} />
        </MetaTags>
    <Header id="3"/>
        {isLoading ? <LoadingSpinner/> :
            <section className='shop-section page-spacing'>
                <div className='breadcrumbs'>
                    <Link to="/">Home / </Link> <strong>Shop</strong>
                </div>
                <div className='row'>
                    <div className='col-md-12 order-md-2 order-1'>
                    {searchParams?.get('s') && <h4 className='title'>Search result for: {searchParams?.get('s')}</h4>}
                        <div className='row mb-4'>
                            {product?.data?.length > 0  && product?.data?.map((result, i) =>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-6 mb-4' key={i+'shop'}>
                                <h6 className="card-title">{result?.styleName}</h6>
                                <div className="card">
                                    <Link to={'/product/'+ result?.product_slug}>
                                        <div className="item-zoom">
                                            <img className="card-img-top zoom-img" src={result?.styleImage}
                                                 alt="Card"/>
                                            {(result?.meta[0]?.user_price < result?.meta[0]?.salePrice) && <div className="badge"> On Sale</div>}
                                        </div>
                                    </Link>
                                    <div className="card-body p-2">
                                        {/* when not login */}
                                        {!localStorage.getItem('token') && <p className='card-text'>Call for Pricing</p>}
                                        {/* when not login end*/}
                                        {/* when login */}
                                        {localStorage.getItem('token') && <p className='card-price sale-price'>{result?.min_max}</p>}
                                        {/* when login end */}
                                        <h4 className="card-title">{result?.brandName}</h4>
                                        <p className='card-price product-name'>{result?.products_name}</p>
                                        <div className='color-box-height'>
                                        {result?.meta?.length > 0  && result?.meta?.map((result1, i) =>
                                        <div className='color-box'>
                                            <input className='color-input' type="radio" name="colorName" value="ash"/>
                                            <label className='color-label'>
                                                <img className='color-image' src={result1?.colorSwatchImage} alt="color" title={result1?.colorName}/>
                                            </label>
                                        </div>
                                            )}
                                        {result?.meta_total >10 && <span className='card-text d-inline-block ms-1'> +{result?.meta_total - 9}</span>}
                                        {/*<p className='card-price'>S - XXL</p>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                        {!product?.data?.length && <h2>Not Found. </h2>}
                        {product?.data?.length > 0 &&
                            <Pagination
                                className="pagination justify-content-center"
                                currentPage={product?.current_page}
                                totalCount={product?.total_pages}
                                pageSize={1}
                                onPageChange={page => products(page)}
                            />
                        }
                    </div>
                </div>
            </section>
        }
    <Footer/>
    </>
  )
};

export default Shop;