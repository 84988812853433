import React,{ useRef, useState, useEffect} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from './shared/Header';
import Footer from './shared/Footer';
import LoadingSpinner from './shared/Loader';
import appConstant from './shared/constant/constant.json';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
function ProductDetail() {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const nav = useNavigate();
    const ref = useRef();
    const ref1 = useRef();
    const props = useParams();
    const [product, setProduct] = React.useState({});
    const [color, setColor] = React.useState('');
    const [colorsData, setColorsData] = React.useState([]);
    const [firstColor, setFirstColor] = React.useState('');
    const [productImage, setProductImage] = React.useState('/images/default-img.webp');
    const [activeImage, setActiveImage] = React.useState('/images/default-img.webp');
    const [frontImage, setFrontImage] = React.useState('/images/default-img.webp');
    const [backImage, setBackImage] = React.useState('/images/default-img.webp');
    const [colorOnModelFrontImage, setColorOnModelFrontImage] = React.useState('/images/default-img.webp');
    const [cartItems, setCartItems] = React.useState([]);
    const [relatedProducts, setRelatedProducts] = React.useState([]);
    const [specification, setSpecification] = React.useState([]);
    const [companion, setCompanion] = React.useState([]);
    const [freshColorData, setFreshColorData] = React.useState([]);
    const [cartMessage, setCartMessage] = React.useState('');
    useEffect(() => {
        productDetails();
        cartTotal();
        relatedProductsData('comparable');
        relatedProductsData('companion');
    }, []);
    const params = {
        // headers: {
        //     "Content-Type": 'application/json',
        //     "Access-Control-Allow-Origin": '*'
        // }
    };
    const CTA = (slug) => {
        window.location.href = '/product/' + slug;
    }
    const productDetails = (isRepeat = false) => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        const productData = new FormData();
        productData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        axios.post(`${appConstant.API_URL}/products/single/${props.id}`, productData, params)
            .then(res => {
                setIsLoading(false);
                setProduct(res?.data?.data);
                setColorsData(res?.data?.data?.colors);
                setFreshColorData(res?.data?.data?.colors);
                setCartItems([]);
                if(!isRepeat){
                    specificationdata(res?.data?.data?.styleID);
                    const defaultColor = res?.data?.data?.colors.length > 0 ? res?.data?.data?.colors[0]?.name : '';
                    setColor(defaultColor);
                    setFirstColor(defaultColor);
                    setProductImage(res?.data?.data?.styleImage);
                    setActiveImage(res?.data?.data?.styleImage);
                    setFrontImage(res?.data?.data?.colors[0]?.data[0]?.colorFrontImage);
                    setBackImage(res?.data?.data?.colors[0]?.data[0]?.colorBackImage);
                    setColorOnModelFrontImage(res?.data?.data?.colors[0]?.data[0]?.colorOnModelFrontImage);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const relatedProductsData = (type) => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        const productData = new FormData();
        productData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        axios.post(`${appConstant.API_URL}/products/related_products/${type}/${props.id}`, productData, params)
            .then(res => {
                setIsLoading(false);
                if(type === 'comparable'){
                    setRelatedProducts(res?.data?.data?.data);
                }else{
                    setCompanion(res?.data?.data?.data);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const specificationdata = (styleID) => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        axios.get(`${appConstant.API_URL}/products/specifications/${styleID}`, params)
            .then(res => {
                setSpecification(res?.data?.data);
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const [inputValues, setInputValue] = useState({
        selectedColor: "one"
    });
    function handleChange(e) {
        const { name, value } = e.target;
        setInputValue({ ...inputValues, [name]: value });
        if(value === 'all'){
            setColor(value);
        }else{
            setColor(firstColor);
        }
    }
    const changeValue = (e, i, m) => {
        const { name, value } = e.target;
        let data1 = [...colorsData];
        data1[i].data[m].quantity = e.target.value;
        setColorsData(data1);
        let data = [...cartItems];
        let j = 0;
        for(let i = 0; i<colorsData.length; i++) {
            if(colorsData[i].data.length > 0) {
                for (let m=0; m<colorsData[i].data.length; m++){
                    if(parseInt(colorsData[i].data[m].quantity) > 0){
                        let element = {};
                        element.sku = colorsData[i].data[m].sku;
                        element.quantity = colorsData[i].data[m].quantity;
                        data[j] = element;
                        j++;
                    }
                }
            }
        }
        setCartItems(data);
    }
    const changeColor = (index) => {
        const getFrontImage = colorsData[index]?.data.length > 0 ? colorsData[index]?.data[0]?.colorFrontImage : appConstant.CDN_URL + '/images/default-img.webp';
        const getBackImage = colorsData[index]?.data.length > 0 ? colorsData[index]?.data[0]?.colorBackImage : appConstant.CDN_URL + '/images/default-img.webp';
        const getColorOnModelFrontImage = colorsData[index]?.data.length > 0 ? colorsData[index]?.data[0]?.colorOnModelFrontImage : appConstant.CDN_URL + '/images/default-img.webp';
        const getActiveImage = colorsData[index]?.data.length > 0 ? (colorsData[index]?.data[0]?.colorFrontImage ? colorsData[index]?.data[0]?.colorFrontImage : colorsData[index]?.data[0]?.colorOnModelFrontImage) : '/images/default-img.webp';
        setActiveImage(getActiveImage);
        setFrontImage(getFrontImage);
        setBackImage(getBackImage);
        setColorOnModelFrontImage(getColorOnModelFrontImage);
        setColor(colorsData[index]?.name);
        setInputValue({selectedColor: 'one'});
    }
    const addToCart = () => {
        setIsLoadingButton(true);
        let freshData = [...freshColorData];
        setColorsData(freshData);
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        const cartData = new FormData();
        cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        cartData.append('cart_items', JSON.stringify(cartItems));
        axios.post(`${appConstant.API_URL}/products/add_to_cart`, cartData, params)
            .then(res => {
                setIsLoadingButton(false);
                setCartMessage(res?.data?.message);
                if(res?.data?.status === 200){
                    cartTotal();
                    productDetails(true);
                }
            })
            .catch(error => {
                setIsLoadingButton(false);
            });
    }
     setTimeout(() => {
        setCartMessage('');
    }, 5000);
    const cartTotal = () => {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        const cartData = new FormData();
        cartData.append('token', localStorage.getItem('token') ? localStorage.getItem('token') : '' );
        axios.post(`${appConstant.API_URL}/products/cart_total`, cartData, params)
            .then(res => {
                if(res?.data?.status === 200){
                    localStorage.setItem('cartTotal', res?.data?.data?.cart_total);
                }else{
                    localStorage.setItem('cartTotal', 0);
                }
            })
            .catch(error => {
            });
    }
    const changeImage = (value) =>{
        setActiveImage(value);
    }
  return (
    <>
        <MetaTags>
            <title>{product?.brandName} {product?.styleName} - {product?.products_name}  | S & S Fashions Inc.</title>
            <meta name="description" content={product?.description} />
            <meta property="og:title" content={product?.brandName + ' - ' + product?.products_name + ' - ' + product?.styleName} />
            <meta property="og:image" content={productImage} />
        </MetaTags>
    <Header id="3"/>
    <div className="product-detail page-spacing">
        <div className='breadcrumbs'>
            <Link to="/">Home / </Link> <Link to="/shop">Shop / </Link>  <strong>{product?.products_name}</strong>
        </div>
        <div className='page-spacing'>
        {isLoading ? <LoadingSpinner/> :
            <div className='row'>
                <div className='col-xl-3 col-lg-6 col-md-6'>
                    <div className='product-gallery'>
                        <div className='full-image'>
                            {activeImage ?
                                <div className='image-group'>
                                    <button data-bs-toggle="modal" data-bs-target="#showImage" style={{border:"none",background:"transparent"}}>
                                    <img src={activeImage ? activeImage : `${appConstant.CDN_URL}/images/default-img.webp`} alt="full"
                                            className='product-full'/>
                                    </button>
                                </div>
                                : ""
                            }
                            {/* <div className='image-group'>
                                <img src={productImage ? productImage : '/images/default-img.webp'} alt="full" className='product-full'/>
                            </div> */}
                        </div>
                        <div className='thumbnails'>
                            {frontImage && <img src={frontImage} alt="thumbnails" className='thumb' onClick={() => changeImage(frontImage)}/> }
                            {backImage && <img src={backImage} alt="thumbnails" className='thumb' onClick={() => changeImage(backImage)}/> }
                            {colorOnModelFrontImage && <img src={colorOnModelFrontImage} alt="thumbnails" className='thumb' onClick={() => changeImage(colorOnModelFrontImage)}/> }
                            {productImage && <img src={productImage} alt="thumbnails" className='thumb' onClick={() => changeImage(productImage)}/> }
                        </div>
                    </div>
                    {/*<h6 className='product-description'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</h6>*/}
                    <h6 className='mini-title'>Category</h6>
                    <h6 className='product-description'>{product?.baseCategory}</h6>
                </div>
                <div className='col-xl-9 col-lg-6 col-md-6'>
                    <h1 className='product-name'>{product?.brandName} - {product?.products_name} - {product?.styleName}</h1>
                    {!localStorage.getItem('token') &&
                    <div className='d-flex justify-content-between'>
                        <div>
                            <h6 className='mini-title links mb-2'>Price starting at ${product?.min_price}</h6>
                            <h6 className='mini-title mb-3'>Size - <span className='product-description'>{product?.sizes?.length > 0 && product?.sizes[0] + ' - ' + product?.sizes[product?.sizes?.length - 1] }</span></h6>
                        </div>
                        <button type="button" className="buttons mb-4" data-bs-toggle="modal" data-bs-target="#login"> Login Here To Order</button>
                    </div>
                    }

                    <h6 className='mini-title'>Color - <span className='product-description'>{color}</span></h6>
                    <div className='color-options'>
                        {colorsData?.length > 0  && colorsData?.map((result, i) =>
                        <div className='color-box' key={i + 'colors'} onClick={() => {changeColor(i)}}>
                            <input className='color-input' type="radio" name="colorName" value="ash"/>
                            <label className='color-label'>
                                <img className='color-image' src={result?.data?.length > 0 ? result?.data[0]?.colorSwatchImage : `${appConstant.CDN_URL}/images/red.webp`} alt="color"/>
                                <span className='color-name'>{result?.name}</span>
                            </label>
                        </div>
                        )}
                    </div>

                    {!localStorage.getItem('token') &&
                    <h6 className='product-description mb-3'><button type="button" className="links" data-bs-toggle="modal" data-bs-target="#login"><small>Login</small></button> to access inventory, pricing, and free marketing tools and resources.</h6>
                    }

                    {localStorage.getItem('token') &&
                        <>
                        {cartMessage && <h6 className='formErrors'>{cartMessage}</h6>}
                    <div className='d-flex justify-content-start'>
                    {isLoadingButton ? <button className="add-button me-3">Add To Cart <img className='ms-2' src={`${appConstant.CDN_URL}/images/default.gif`} width={'16px'} height={'16px'} alt={'default'}/></button> :
                        <button className="add-button me-3" disabled={cartItems.length === 0} onClick={() => {addToCart()}}>Add To Cart</button>
                    }
                        <select className='color-choice' name="selectedColor" value={inputValues.selectedColor} onChange={(e) => {handleChange(e)}}>
                            <option value="one">1 color</option>
                            <option value='all'>All colors</option>
                        </select>
                    </div>
                    {colorsData?.length > 0  && colorsData?.map((result, i) =>
                    <>
                        {(color === result?.name || color === 'all') &&
                            <div className='order-quantity' key={i + 'colorData'}>
                                <div className='color-big-box'>
                                    <p className="product-description border-bottom mb-0 px-2">Color</p>
                                    <img className='px-2 mt-2'
                                         src={result?.data?.length > 0 ? result?.data[0]?.colorSwatchImage : `${appConstant.CDN_URL}/images/red.webp`}
                                         alt="color" width={"60"}/>
                                    <p className='product-description mb-0 px-2'>{result?.name}</p>
                                </div>
                                <div className='color-big-box'>
                                    <div className='blank border-bottom'></div>
                                    <p className='product-description mb-0 px-2'>Case Qty</p>
                                    <p className='product-description mb-0 px-2'>Regular Price</p>
                                    <p className='product-description mb-0 px-2'><b>Sale Price</b></p>
                                    <p className='product-description mb-0 px-2'>Order Qty</p>
                                    <p className='product-description mb-0 px-2'><b>Bronx WHS</b></p>
                                    <p className='product-description mb-0 px-2'><b>Bulk WHS</b></p>
                                </div>
                                {result?.data?.length > 0 && result?.data?.map((result1, m) =>
                                    <>
                                        <div className='color-small-box'>
                                            <p className="product-description border-bottom mb-0 px-2">{result1?.sizeName}</p>
                                            <p className='product-description mb-0 px-2'>{result1?.CaseQty}</p>
                                            <p className='product-description mb-0 px-2'>${result1?.piecePrice}</p>
                                            <p className='product-description mb-0 px-2' style={{color: 'darkblue', 'font-weight': '800'}}><b>${result1?.user_price}</b></p>
                                            <input type='number' name="quantity" min="0" value={result1?.quantity}
                                                   onChange={(e) => changeValue(e, i, m)}/>
                                            <p className='product-description mb-0 px-2'><b>{result1?.our_quantity}</b></p>
                                            <p className='product-description mb-0 px-2'><b>{result1?.total_quantity}</b></p>

                                        </div>
                                    </>
                                )}
                            </div>
                        }
                    </>
                    )}
                    {cartMessage && <h6 className='formErrors ms-5'>{cartMessage}</h6>}
                    {isLoadingButton ? <button className="add-button me-3">Add To Cart <img className='ms-2' src={`${appConstant.CDN_URL}/images/default.gif`} width={'16px'} height={'16px'} alt={'default'}/></button> :
                        <button className="add-button me-3 mb-4" disabled={cartItems.length === 0} onClick={() => {addToCart()}}>Add To Cart</button>
                    }
                        </>
                    }

                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                        <a className="nav-link active" data-bs-toggle="tab" href="#description"><h1 className='product-name'>Description</h1></a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#specification"><h1 className='product-name'>Specification</h1></a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div id="description" className="container tab-pane active"><br/>
                        <h6 className='mini-title'>Description</h6>
                        <div className='product-description' dangerouslySetInnerHTML={{__html: product?.products_description}}></div>
                        </div>
                        <div id="specification" className="container tab-pane fade"><br/>

                            {specification?.sizes?.length > 0 &&
                            <>
                                <h6 className='mini-title'>Specification</h6>
                                <table border={1} width={'100%'} className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            {specification?.sizes?.length > 0 && specification?.sizes?.map((result, i) =>
                                                <td key={'spec'+ i}>{result}</td>
                                            )}
                                        </tr>

                                        {specification?.spec?.length > 0 && specification?.spec?.map((result, i) =>
                                        <tr key={'specName'+ i}><td>{result.name}</td>
                                            {result?.data?.length > 0 && result?.data?.map((result1, m) =>
                                                <td key={'specData' + m}>{result1.value}</td>
                                            )}
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
        </div>

        {relatedProducts?.length > 0 && <>
        <hr className='my-4'/>
        <h1 className='product-name'>Related Products</h1>
        <div className='shop-section'>
            <div className='row mb-3'>
                {companion?.length > 0 && companion?.map((result, i) =>
                    <>
                <div className='col-xl-2 col-sm-4 col-6 mb-4' key={'related-products' + i}>
                    <h6 className="card-title">{result?.styleName}</h6>
                    <div className="card">
                        <div onClick={() => CTA(result?.product_slug)}>
                            <div className="item-zoom">
                                <img className="card-img-top zoom-img" src={result?.styleImage}
                                     alt="Card"/>
                                {(result?.meta[0]?.user_price < result?.meta[0]?.salePrice) && <div className="badge"> On Sale</div>}
                            </div>
                        </div>
                        <div className="card-body p-2">
                            {/* when not login */}
                            {!localStorage.getItem('token') && <p className='card-text'>Call for Pricing</p>}
                            {/* when not login end*/}
                            {/* when login */}
                            {localStorage.getItem('token') && <p className='card-price sale-price'>Starting at ${result?.meta[0]?.user_price}</p>}
                            {/* when login end */}
                            <h4 className="card-title">{result?.brandName}</h4>
                            <p className='card-price product-name'>{result?.products_name}</p>
                            <div className='color-box-height'>
                                {result?.meta?.length > 0  && result?.meta?.map((result1, i) =>
                                    <div className='color-box'>
                                        <input className='color-input' type="radio" name="colorName" value="ash"/>
                                        <label className='color-label'>
                                            <img className='color-image' src={result1?.colorSwatchImage} alt="color" title={result1?.colorName}/>
                                        </label>
                                    </div>
                                )}
                                {result?.meta_total >10 && <span className='card-text d-inline-block ms-1'> +{result?.meta_total - 9}</span>}
                                {/*<p className='card-price'>S - XXL</p>*/}
                            </div>
                        </div>
                    </div>
                </div>
                    </>
                )}
            </div>
        </div>
        </>}

        {relatedProducts?.length > 0 && <>
        <hr className='my-4'/>
        <h1 className='product-name'>Comparable Styles</h1>
        <div className='shop-section'>
            <div className='row mb-3'>
                {relatedProducts?.length > 0 && relatedProducts?.map((result, i) =>
                    <>
                <div className='col-xl-2 col-sm-4 col-6 mb-4' key={'related-products' + i}>
                    <h6 className="card-title">{result?.styleName}</h6>
                    <div className="card">
                        <div onClick={() => CTA(result?.product_slug)}>
                            <div className="item-zoom">
                                <img className="card-img-top zoom-img" src={result?.styleImage}
                                     alt="Card"/>
                                {(result?.meta[0]?.user_price < result?.meta[0]?.salePrice) && <div className="badge"> On Sale</div>}
                            </div>
                        </div>
                        <div className="card-body p-2">
                            {/* when not login */}
                            {!localStorage.getItem('token') && <p className='card-text'>Call for Pricing</p>}
                            {/* when not login end*/}
                            {/* when login */}
                            {localStorage.getItem('token') && <p className='card-price sale-price'>Starting at ${result?.meta[0]?.user_price}</p>}
                            {/* when login end */}
                            <h4 className="card-title">{result?.brandName}</h4>
                            <p className='card-price product-name'>{result?.products_name}</p>
                            <div className='color-box-height'>
                                {result?.meta?.length > 0  && result?.meta?.map((result1, i) =>
                                    <div className='color-box'>
                                        <input className='color-input' type="radio" name="colorName" value="ash"/>
                                        <label className='color-label'>
                                            <img className='color-image' src={result1?.colorSwatchImage} alt="color" title={result1?.colorName}/>
                                        </label>
                                    </div>
                                )}
                                {result?.meta_total >10 && <span className='card-text d-inline-block ms-1'> +{result?.meta_total - 9}</span>}
                                {/*<p className='card-price'>S - XXL</p>*/}
                            </div>
                        </div>
                    </div>
                </div>
                    </>
                )}
            </div>
        </div>
        </>}
        {/* <div className='d-flex justify-content-center mt-3'>
            <Link className='filled-button buttons text-center' to="/shop">View More</Link>
        </div>  */}
    </div>
    <div className="lightbox modal" id="showImage">
        <button type="button" className="close-btn" data-bs-dismiss="modal">&times;</button>
        <div className="modal-dialog modal-fullscreen modal-dialog-centered">
        <img className='modal-image' src={activeImage ? activeImage : `${appConstant.CDN_URL}/images/default-img.webp`} alt="attachment"/>
        </div>
    </div>
    <Footer/>
    </>
  )
};

export default ProductDetail;
