import React, {useEffect, useState} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import LoadingSpinner from './shared/Loader';
function Home() {
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    products();
  }, []);

  const products = () => {
    setIsLoading(true);
    const params = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    axios.get(`${appConstant.API_URL}/brands/flash_sale/`, params)
        .then(res => {
          setIsLoading(false);
          setProduct(res?.data?.data);
        })
        .catch(error => {
          setIsLoading(false);
        });
  }
  return (
    <>
        {/* header  */}
        <Header id="1"/>
        {/* hero  */}
        <section className='hero-section mb-4'>
          <div id="hero" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#hero" data-bs-slide-to="0" className="active"></button>
              <button type="button" data-bs-target="#hero" data-bs-slide-to="1"></button>
              <button type="button" data-bs-target="#hero" data-bs-slide-to="2"></button>
              <button type="button" data-bs-target="#hero" data-bs-slide-to="3"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <Link to="/category/nyccollection">
                  <img src={`${appConstant.CDN_URL}/images/nyc.webp`} alt="brands" className="d-inline-block"/>
                </Link>
              </div>
              <div className="carousel-item">
                <Link to="/brands/fruit-of-the-loom">
                  <img src={`${appConstant.CDN_URL}/images/banner3.webp`} alt="brands" className="d-inline-block"/>
                </Link>
              </div>
              <div className="carousel-item">
                <Link to="/brands/gildan">
                  <img src={`${appConstant.CDN_URL}/images/banner1.webp`} alt="brands" className="d-inline-block"/>
                </Link>
              </div>
              <div className="carousel-item">
                <Link to="/brands/jerzees">
                  <img src={`${appConstant.CDN_URL}/images/banner2.webp`} alt="brands" className="d-inline-block"/>
                </Link>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#hero" data-bs-slide="prev">
              <span className="carousel-control-prev-icon"></span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#hero" data-bs-slide="next">
              <span className="carousel-control-next-icon"></span>
            </button>
          </div>
        </section>
        {/* flash sale  */}
        <section className='flash-sale mb-4'>
          <div className='row'>
            <div className='col-lg-7 mb-4'>
              <Link to='/brands/activa'>
                <div className='shine'>
                    <img src={`${appConstant.CDN_URL}/images/offers.webp`} alt="Los Angeles" className="offers"/>
                </div>
              </Link>
            </div>
            <div className='col-lg-5'>
              <div className='sale-carousel'>
                <h6 className='heading'>Flash Sale</h6>
                {isLoading ? <LoadingSpinner/> :
                <div id="flash-sale" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner">
                    {product?.data?.length > 0  && product?.data?.map((result, i) =>
                    <div key={'flash-sale'+i} className={i === 0 ? "carousel-item active" : "carousel-item"}>
                      <Link to={'/product/'+ result?.product_slug}>
                        <div className='row d-flex align-items-center'>
                          <div className='col-4'>
                            <div className="item-zoom">
                              <img className="zoom-img" src={result?.styleImage} alt="Card"/>
                            </div>
                          </div>
                          <div className='col-8'>
                            <h6 className='product-name'>{result?.products_name}</h6>
                            <h6 className='product-description' dangerouslySetInnerHTML={{__html: result?.products_description}}></h6>
                            <h6 className='product-price'>Starting at ${result?.meta[0]?.user_price}</h6>
                          </div>
                        </div>

                      </Link>
                    </div>
                    )}
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#flash-sale" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#flash-sale" data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                  </button>
                </div>
                }
              </div>
            </div>
          </div>
        </section>
        {/* sale offer  */}
        <section className='sale-offers mb-4'>
          <div className='row'>
            <div className='col-md-4 col-sm-6 mb-4'>
              <Link to="/brands/hanes">
                <div className='shine'>
                  <img src={`${appConstant.CDN_URL}/images/sale-offer-1.webp`} alt="Los Angeles" className="w-100"/>
                </div>
              </Link>
            </div>
            <div className='col-md-4 col-sm-6 mb-4 d-block d-sm-none d-md-block'>
              <Link to="/brands/next-level">
                <div className='shine'>
                  <img src={`${appConstant.CDN_URL}/images/sale-offer-2.webp`} alt="Los Angeles" className="w-100"/>
                </div>
              </Link>
            </div>
            <div className='col-md-4 col-sm-6'>
              <Link to="/brands/independent-trading-co">
                <div className='shine'>
                  <img src={`${appConstant.CDN_URL}/images/sale-offer-3.webp`} alt="Los Angeles" className="w-100"/>
                </div>
              </Link>
            </div>
          </div>
        </section>
        {/* category  */}
        <section className='category mb-4'>
          <h1 className='heading'>Shop By Category</h1>
          <div className='row'>
            <div className='col-lg-2 col-sm-4 col-6 mb-4'>
              <Link to="/category/t_shirts">
                <div className='category-box'>
                  <div className="item-zoom">
                    <img className="zoom-img" src={`${appConstant.CDN_URL}/images/category-1.webp`} alt="Card"/>
                  </div>
                  <h6 className='category-name'>Top</h6>
                </div>
              </Link>
            </div>
            <div className='col-lg-2 col-sm-4 col-6 mb-4'>
              <Link to="/category/fleece">
                <div className='category-box'>
                  <div className="item-zoom">
                    <img className="zoom-img" src={`${appConstant.CDN_URL}/images/category-2.webp`} alt="Card"/>
                  </div>
                  <h6 className='category-name'>Bottom</h6>
                </div>
              </Link>
            </div>
            <div className='col-lg-2 col-sm-4 col-6 mb-4'>
              <Link to="/category/headwear">
                <div className='category-box'>
                  <div className="item-zoom">
                    <img className="zoom-img" src={`${appConstant.CDN_URL}/images/category-3.webp`} alt="Card"/>
                  </div>
                  <h6 className='category-name'>Headwear</h6>
                </div>
              </Link>
            </div>
            <div className='col-lg-2 col-sm-4 col-6 mb-4'>
              <Link to="/category/outerwear">
                <div className='category-box'>
                  <div className="item-zoom">
                    <img className="zoom-img" src={`${appConstant.CDN_URL}/images/category-4.webp`} alt="Card"/>
                  </div>
                  <h6 className='category-name'>Outerwear</h6>
                </div>
              </Link>
            </div>
            <div className='col-lg-2 col-sm-4 col-6 mb-4'>
              <Link to="/category/accessories">
                <div className='category-box'>
                  <div className="item-zoom">
                    <img className="zoom-img" src={`${appConstant.CDN_URL}/images/category-5.webp`} alt="Card"/>
                  </div>
                  <h6 className='category-name'>Accessories</h6>
                </div>
              </Link>
            </div>
            <div className='col-lg-2 col-sm-4 col-6 mb-4'>
              <Link to="/category/ppe">
                <div className='category-box'>
                  <div className="item-zoom">
                    <img className="zoom-img" src={`${appConstant.CDN_URL}/images/category-6.webp`} alt="Card"/>
                  </div>
                  <h6 className='category-name'>PPE</h6>
                </div>
              </Link>
            </div>
          </div>
        </section>
        {/* client  */}
        <section className='client-section mb-4'>
          <div className='client-box'>
            <img className="clients" src={`${appConstant.CDN_URL}/images/logo-5.webp`} alt="logo-4" />
          </div>
          <div className='client-box'>
            <img className="clients" src={`${appConstant.CDN_URL}/images/Fruit_logo.svg.webp`} alt="logo-1" />
          </div>
          <div className='client-box'>
            <img className="clients" src={`${appConstant.CDN_URL}/images/logo-8.webp`} alt="logo-6" />
          </div>
          <div className='client-box'>
            <img className="clients" src={`${appConstant.CDN_URL}/images/nextlevel.webp`} alt="logo-2" />
          </div>
          <div className='client-box'>
            <img className="clients" src={`${appConstant.CDN_URL}/images/logo-2.webp`} alt="logo-3" />
          </div>
          <div className='client-box'>
            <img className="clients" src={`${appConstant.CDN_URL}/images/independent.webp`} alt="logo-3" />
          </div>

          <div className='client-box'>
            <img className="clients" src={`${appConstant.CDN_URL}/images/logo-6.webp`} alt="logo-5" />
          </div>
          <div className='client-box'>
            <img className="clients" src={`${appConstant.CDN_URL}/images/activa.webp`} alt="logo-6" />
          </div>

        </section>
        {/* footer  */}
        <Footer/>
    </>
  )
};

export default Home;
