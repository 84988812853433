import { Link } from "react-router-dom";
import './BlogsCard.css';
import appConstant from "../constant/constant.json";

function BlogsCard(props) {
    return (
        <Link to={'/blogs/' + props?.slug} className='blog-card'>
            <img className="blog-img" src={props?.image} alt={props?.title}/>
            <div className="blog-body">
                <p className="blog-posted">{props?.author}, {props?.posted} </p>
                <h6 className='blog-title'>{props?.title}</h6>
                <div className='blog-content' dangerouslySetInnerHTML={{__html: props?.description}}></div>
            </div>
        </Link>
    );
  }
  
  export default BlogsCard;