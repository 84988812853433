import React, {useEffect, useState} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Sidebar from './shared/Sidebar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import LoadingSpinner from './shared/Loader';
function Shipping() {
  useEffect(() => {
    getShippingDetails();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [shippingData, setShippingData] = useState(false);
  const [inputValues, setInputValue] = useState({
    shippingId: "",
    fname: "",
    lname: "",    
    number: "",
    email: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    company: "",
  });
  function handleChange(e) {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  const [validation, setValidation] = useState({
    fname: "",
    lname: "",    
    number: "",
    email: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    company: "",
  });
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    //first validation
    const alphabetCond = /^[a-zA-Z ]*$/;
    if (!inputValues.fname) {
    errors.fname = "First name is required";
    } else if (!inputValues.fname.match(alphabetCond)) {
    errors.fname = "Please enter valid first name";
    } else {
    errors.fname = "";
    }
    //last validation
    if (!inputValues.lname) {
    errors.lname = "Last name is required";
    } else if (!inputValues.lname.match(alphabetCond)) {
    errors.lname = "Please enter valid last name";
    } else {
    errors.lname = "";
    }
    //number validation
    const numberCond = /^[0-9\b]+$/;
    if (!inputValues.number) {
    errors.number = "Number is required";
    } else if (!inputValues.number.match(numberCond)) {
    errors.number = "Please enter valid number";
    } else {
    errors.number = "";
    }
    // email validation
    const emailCond = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!inputValues.email) {
      errors.email = "Email is required";
    } else if (!inputValues.email.match(emailCond)) {
      errors.email = "Please enter valid email address";
    } else {
      errors.email = "";
    }    
    //address validation
    if (!inputValues.address) {
    errors.address = "Address is required";
    }else {
    errors.address = "";
    }
    //country validation
    if (!inputValues.country) {
      errors.country = "Country is required";
      }else {
      errors.country = "";
    }
    //state validation
    if (!inputValues.state) {
      errors.state = "State is required";
      }else {
      errors.state = "";
    }
    //city validation
    if (!inputValues.city) {
      errors.city = "City is required";
      }else {
      errors.city = "";
    }
    //zip validation
    if (!inputValues.zip) {
      errors.zip = "Zip is required";
      }else {
      errors.zip = "";
    }
    //company validation
    if (!inputValues.company) {
      errors.company = "Company is required";
      }else {
      errors.company = "";
    }

    setValidation(errors);
    if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === '' && errors.address === '' && errors.country === '' && errors.state === ''&& errors.city === '' && errors.zip === '' && errors.company === ''){
      return true;
    }else{
      return false;
    }
  };
  const updateShippingAddress = () => {
    debugger;
    const isSuccess = checkValidation();
    if(isSuccess) {
      setIsLoadingButton(true);
      const shippingDetailsData = new FormData();
      shippingDetailsData.append('token', localStorage.getItem('token') );
      shippingDetailsData.append('first_name', inputValues.fname );
      shippingDetailsData.append('last_name', inputValues.lname );
      shippingDetailsData.append('email', inputValues.email );
      shippingDetailsData.append('address', inputValues.address );
      shippingDetailsData.append('country', inputValues.country );
      shippingDetailsData.append('mobile_number', inputValues.number );
      shippingDetailsData.append('state', inputValues.state );
      shippingDetailsData.append('city', inputValues.city );
      shippingDetailsData.append('postal_code', inputValues.zip );
      shippingDetailsData.append('company', inputValues.company );
      const params = {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*"
      };
      const id = inputValues.shippingId;
      axios.post(`${appConstant.API_URL}/user/update_address/shipping/${id}`, shippingDetailsData, params)
      .then(res => {
          setIsLoadingButton(false);
          if (res?.data?.status === 200) {
              console.log(res?.data?.message);
          }else {

          }
      })
      .catch(error => {
          setIsLoadingButton(false);
      });
    }
  }
  const addShippingAddressApi = () => {
      setIsLoadingButton(true);
      const shippingDetailsData = new FormData();
      shippingDetailsData.append('token', localStorage.getItem('token') );
      shippingDetailsData.append('first_name', addInputValues.addFname );
      shippingDetailsData.append('last_name', addInputValues.addLname );
      shippingDetailsData.append('email', addInputValues.addEmail );
      shippingDetailsData.append('address', addInputValues.addAddress );
      shippingDetailsData.append('country', addInputValues.addCountry );
      shippingDetailsData.append('mobile_number', addInputValues.addNumber );
      shippingDetailsData.append('state', addInputValues.addState );
      shippingDetailsData.append('city', addInputValues.addCity );
      shippingDetailsData.append('postal_code', addInputValues.addZip );
      shippingDetailsData.append('company', addInputValues.addCompany );
      const params = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      };
      axios.post(`${appConstant.API_URL}/user/update_address/shipping`, shippingDetailsData, params)
          .then(res => {
            setIsLoadingButton(false);
            if (res?.data?.status === 200) {
              console.log(res?.data?.message);
              const addData = {
                addFname: "",
                addLname: "",
                addNumber: "",
                addEmail: "",
                addAddress: "",
                addCountry: "",
                addState: "",
                addCity: "",
                addZip: "",
                addCompany: ""
              }
              setAddInputValue(addData);
              getShippingDetails();
            }else {

            }
          })
          .catch(error => {
            setIsLoadingButton(false);
          });
  }
  const [addInputValues, setAddInputValue] = useState({
    addFname: "",
    addLname: "",    
    addNumber: "",
    addEmail: "",
    addAddress: "",
    addCountry: "",
    addState: "",
    addCity: "",
    addZip: "",
    addCompany: ""
  });
  function handleAddChange(e) {
    const { name, value } = e.target;
    setAddInputValue({ ...addInputValues, [name]: value });
  }
  const [addValidation, setAddValidation] = useState({
    addFname: "",
    addLname: "",    
    addNumber: "",
    addEmail: "",
    addAddress: "",
    addCountry: "",
    addState: "",
    addCity: "",
    addZip: "",
    addCompany: ""
  });
  const checkAddValidation = () => {
    let errors = JSON.parse(JSON.stringify(addValidation));

    //first validation
    const alphabetCond = /^[a-zA-Z ]*$/;
    if (!addInputValues.addFname) {
    errors.addFname = "First name is required";
    } else if (!addInputValues.addFname.match(alphabetCond)) {
    errors.addFname = "Please enter valid first name";
    } else {
    errors.addFname = "";
    }
    //last validation
    if (!addInputValues.addLname) {
    errors.addLname = "Last name is required";
    } else if (!addInputValues.addLname.match(alphabetCond)) {
    errors.addLname = "Please enter valid last name";
    } else {
    errors.addLname = "";
    }
    //number validation
    const numberCond = /^[0-9\b]+$/;
    if (!addInputValues.addNumber) {
    errors.addNumber = "Number is required";
    } else if (!addInputValues.addNumber.match(numberCond)) {
    errors.addNumber = "Please enter valid number";
    } else {
    errors.addNumber = "";
    }
    // email validation
    const emailCond = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!addInputValues.addEmail) {
      errors.addEmail = "Email is required";
    } else if (!addInputValues.addEmail.match(emailCond)) {
      errors.addEmail = "Please enter valid email address";
    } else {
      errors.addEmail = "";
    }    
    //address validation
    if (!addInputValues.addAddress) {
    errors.addAddress = "Address is required";
    }else {
    errors.addAddress = "";
    }
    //country validation
    if (!addInputValues.addCountry) {
      errors.addCountry = "Country is required";
      }else {
      errors.addCountry = "";
    }
    //state validation
    if (!addInputValues.addState) {
      errors.addState = "State is required";
      }else {
      errors.addState = "";
    }
    //city validation
    if (!addInputValues.addCity) {
      errors.addCity = "City is required";
      }else {
      errors.addCity = "";
    }
    //zip validation
    if (!addInputValues.addZip) {
      errors.addZip = "Zip is required";
      }else {
      errors.addZip = "";
    }
    //company validation
    if (!addInputValues.addCompany) {
      errors.addCompany = "Company is required";
      }else {
      errors.addCompany = "";
    }

    setAddValidation(errors);
    if(errors.addFname === '' && errors.addLname === '' && errors.addNumber === '' && errors.addEmail === '' && errors.addAddress === '' && errors.addCountry === '' && errors.addState === ''&& errors.addCity === '' && errors.addZip === '' && errors.addCompany === ''){
      return true;
    }else{
      return false;
    }
  };
  const addShippingAddress = () => {
    const isSuccess = checkAddValidation();
    if(isSuccess) {
      setIsLoadingButton(true);
      addShippingAddressApi();
    }
  }
  const getShippingDetails = () => {
    setIsLoading(true);
    const shippingDetailsData = new FormData();
    shippingDetailsData.append('token', localStorage.getItem('token') );
    const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/address/shipping`, shippingDetailsData, params)
          .then(res => {
              setIsLoading(false);
              if (res?.data?.status === 200) {
                setShippingData(res?.data?.data);
              }else {
                
              }
          })
          .catch(error => {
              setIsLoading(false);
          });
  }

  const manage_address = (type, id) => {
    const shippingDetailsData = new FormData();
    shippingDetailsData.append('token', localStorage.getItem('token') );
    shippingDetailsData.append('id', id);
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/manage_address/${type}/${id}`, shippingDetailsData, params)
        .then(res => {
          if (res?.data?.status === 200) {
            getShippingDetails();
          }else {

          }
        })
        .catch(error => {
        });
  }
  const getShipping = (id) => {
    setIsLoading(true);
    const shippingDetailsData = new FormData();
    shippingDetailsData.append('token', localStorage.getItem('token') );
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/address/shipping/${id}`, shippingDetailsData, params)
        .then(res => {
          setIsLoading(false);
          if (res?.data?.status === 200) {
            const newData = {
              shippingId: res?.data?.data?.id,
              fname:  res?.data?.data?.first_name,
              lname: res?.data?.data?.last_name,
              number: res?.data?.data?.mobile_number,
              email: res?.data?.data?.email,
              address: res?.data?.data?.address,
              country: res?.data?.data?.country,
              state: res?.data?.data?.state,
              city: res?.data?.data?.city,
              zip: res?.data?.data?.postal_code,
              company: res?.data?.data?.company
            }
            setInputValue(newData);
          }else {

          }
        })
        .catch(error => {
          setIsLoading(false);
        });
  }
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='cart-section page-spacing'>
          <div className='breadcrumbs'>
              <Link to="/">Home / </Link> <strong>Shipping</strong>
          </div>
          <div className='row'>
            <div className='col-md-3'>
                <div className='card'>
                  <div className="card-body">
                      <h6 className='title'>MY ACCOUNT</h6>
                      <Sidebar id='3'/>
                  </div>
                </div>
            </div>
            <div className='col-md-9'>
              <h6 className='title mb-4'>Shipping Address</h6>
              <form>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label>First Name</label>
                    <input type="text" placeholder="First Name" name="addFname" value={addInputValues.addFname} onChange={(e) => handleAddChange(e)}/>
                    {addValidation.addFname && <p className='formErrors'>{addValidation.addFname}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Last Name</label>
                    <input type="text" placeholder="Last Name" name="addLname" value={addInputValues.addLname} onChange={(e) => handleAddChange(e)}/>
                    {addValidation.addLname && <p className='formErrors'>{addValidation.addLname}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Mobile Number</label>
                    <input type="text" placeholder="Mobile Number" name="addNumber" value={addInputValues.addNumber} onChange={(e) => handleAddChange(e)}/>
                    {addValidation.addNumber && <p className='formErrors'>{addValidation.addNumber}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Email</label>
                    <input type="text" placeholder="Email" name="addEmail" value={addInputValues.addEmail} onChange={(e) => handleAddChange(e)}/>
                    {addValidation.addEmail && <p className='formErrors'>{addValidation.addEmail}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Address</label>
                    <input type='text' name='addAddress' placeholder='Address' value={addInputValues.addAddress} onChange={(e) => handleAddChange(e)}/>
                    {addValidation.addAddress && <p className='formErrors'>{addValidation.addAddress}</p>}
                  </div>
                  <div className='col-sm-6'>
                    <label>Country</label>
                    <select name="addCountry"  value={addInputValues.addCountry} onChange={(e) => handleAddChange(e)}>
                        <option value="">Select Country</option>
                        <option value="USA">USA</option>
                    </select>      
                    {addValidation.addCountry && <p className='formErrors'>{addValidation.addCountry}</p>}          
                  </div>
                  <div className='col-sm-6'>
                    <label>State</label>
                    <select name="addState" value={addInputValues.addState} onChange={(e) => handleAddChange(e)}>
                        <option value="">Select State</option>
                      {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                          <option value={result} key={i}>{result}</option>
                      )}
                    </select>  
                    {addValidation.addState && <p className='formErrors'>{addValidation.addState}</p>}             
                  </div>
                  <div className='col-sm-6'>
                    <label>City</label>
                    <input type="text" placeholder='Enter City' name='addCity' value={addInputValues.addCity} onChange={(e) => handleAddChange(e)}/>  
                    {addValidation.addCity && <p className='formErrors'>{addValidation.addCity}</p>}            
                  </div>
                  <div className='col-sm-6'>
                    <label>Zip/Postal Code</label>
                    <input type="text" placeholder='Enter Zip/Postal Code' name='addZip'  value={addInputValues.addZip} onChange={(e) => handleAddChange(e)}/> 
                    {addValidation.addZip && <p className='formErrors'>{addValidation.addZip}</p>}             
                  </div>
                  <div className='col-sm-6'>
                    <label>Company</label>
                    <input type="text" placeholder='Enter Company Name' name='addCompany'  value={addInputValues.addCompany} onChange={(e) => handleAddChange(e)}/> 
                    {addValidation.addCompany && <p className='formErrors'>{addValidation.addCompany}</p>}               
                  </div>
                </div>
                {isLoadingButton ? <div className='text-center mt-3'><button type='button' className="buttons">Add Shipping Address <img alt="loader" className='ms-2' src={`${appConstant.CDN_URL}/images/default.gif`} width={'16px'} height={'16px'}/></button></div> :
                  <div className='text-center mt-3'><button type='button' className="buttons" onClick={() => {addShippingAddress()}}>Add Shipping Address</button></div>
                }            
              </form>
              <div className='row mt-3'>
                {shippingData?.length > 0 && shippingData?.map((result, i) =>
                <>
                  <div className='col-lg-4 col-sm-6 mb-4' key={'shipping'+i}>
                    <div className={result?.default_address === '0' ? 'card add-address' : 'card add-address default'}>
                      <div className='card-body position-relative p-4 pb-2'>
                      {result?.default_address === '0' && <button type="button" className="position-absolute remove-address" onClick={() => {manage_address('delete', result?.id)}}>&times;</button> }
                          <p className='address'>
                            <div style={{textTransform : 'capitalize', fontWeight : '700'}}> {result?.company}</div>
                            <span style={{textTransform : 'capitalize'}}>{result?.first_name} {result?.last_name}</span>, {result?.address} {result?.city} {result?.state} {result?.country} {result?.postal_code}
                          </p>
                          <div className='d-flex justify-content-between flex-wrap'>
                            <button type="button" className='buttons mini-buttons mb-2' data-bs-toggle="modal" data-bs-target="#edit-address" onClick={() => {getShipping(result?.id)}}>Edit Address</button>
                            {result?.default_address === '0' && <button className='buttons mini-buttons mb-2' onClick={() => {manage_address('default', result?.id)}}>Make Default</button> }
                          </div>
                      </div>
                    </div>
                  </div>
                </>
                )}
                </div>
            </div>
          </div>
        </div>
        {/* footer  */}
        <Footer/>
        {/* edit-address popup  */}
        <div className="modal back-shadow" id="edit-address">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                    <div className="modal-body p-4">
                    {isLoading ? <LoadingSpinner/> :
                      <form>
                        <div className='row'>
                          <div className='col-sm-12'>
                            <label>First Name</label>
                            <input type="text" placeholder="First Name" name="fname" value={inputValues.fname} onChange={(e) => handleChange(e)}/>
                            {validation.fname && <p className='formErrors'>{validation.fname}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Last Name</label>
                            <input type="text" placeholder="Last Name" name="lname" value={inputValues.lname} onChange={(e) => handleChange(e)}/>
                            {validation.lname && <p className='formErrors'>{validation.lname}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Mobile Number</label>
                            <input type="text" placeholder="Mobile Number" name="number" value={inputValues.number} onChange={(e) => handleChange(e)}/>
                            {validation.number && <p className='formErrors'>{validation.number}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Email</label>
                            <input type="text" placeholder="Email" name="email" value={inputValues.email} onChange={(e) => handleChange(e)}/>
                            {validation.email && <p className='formErrors'>{validation.email}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Address</label>
                            <input type='text' name='address' placeholder='Address' value={inputValues.address} onChange={(e) => handleChange(e)}/>
                            {validation.address && <p className='formErrors'>{validation.address}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label>Country</label>
                            <select name="country"  value={inputValues.country} onChange={(e) => handleChange(e)}>
                                <option value="">Select Country</option>
                                <option value="USA">USA</option>
                            </select>      
                            {validation.country && <p className='formErrors'>{validation.country}</p>}          
                          </div>
                          <div className='col-sm-12'>
                            <label>State</label>
                            <select name="state" value={inputValues.state} onChange={(e) => handleChange(e)}>
                                <option value="">Select State</option>
                              {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                                  <option value={result} key={i}>{result}</option>
                              )}
                            </select>  
                            {validation.state && <p className='formErrors'>{validation.state}</p>}             
                          </div>
                          <div className='col-sm-12'>
                            <label>City</label>
                            <input type="text" placeholder='Enter City' name='city' value={inputValues.city} onChange={(e) => handleChange(e)}/>  
                            {validation.city && <p className='formErrors'>{validation.city}</p>}            
                          </div>
                          <div className='col-sm-12'>
                            <label>Zip/Postal Code</label>
                            <input type="text" placeholder='Enter Zip/Postal Code' name='zip'  value={inputValues.zip} onChange={(e) => handleChange(e)}/> 
                            {validation.zip && <p className='formErrors'>{validation.zip}</p>}             
                          </div>
                          <div className='col-sm-12'>
                            <label>Company</label>
                            <input type="text" placeholder='Enter Company Name' name='company'  value={inputValues.company} onChange={(e) => handleChange(e)}/> 
                            {validation.company && <p className='formErrors'>{validation.company}</p>}               
                          </div>
                        </div>
                        {isLoadingButton ? <div className='text-center mt-3'><button type='submit' className="buttons">Update <img alt="loader" className='ms-2' src={`${appConstant.CDN_URL}/images/default.gif`} width={'16px'} height={'16px'}/></button></div> :
                          <div className='text-center mt-3'><button type='button' className="buttons" onClick={() => {updateShippingAddress()}}>Update</button></div>
                        }            
                      </form>
                    }
                    </div>
                </div>
            </div>
        </div>
    </>
  )
};
export default Shipping;