import React, {useEffect, useState} from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Sidebar from './shared/Sidebar';
import { Link } from 'react-router-dom';
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import Moment from 'moment';
import DataTable from 'react-data-table-component';
function Orders() {
  useEffect(() => {
    getOrders();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState([]);
    const ExpandedComponent = ({ data }) => <small><table className="table table-bordered">
        <thead>
        <tr>
            <td className='first-child'>ITEM(S)</td>
            <td>COLORS</td>
            <td>SIZE</td>
            <td>PRICE</td>
            <td>QTY</td>
            <td>SUB TOTAL</td>
        </tr>
        </thead>
        <tbody>
                {data?.meta?.length > 0 && data?.meta?.map((result, m) =>
                    <>
                        <tr key={m + 'cartItems'}>
                            <td rowSpan={result?.length} className='first-child'>
                                <img className='product-image me-2' src={result.product_image}
                                     alt="product"/>
                                 <h6 className='product-name mt-2'>{result?.product_title}</h6>
                            </td>
                            <td>{result?.color}</td>
                            <td>{result?.size}</td>
                            <td>${result?.price}</td>
                            <td>{result?.quantity}</td>
                            <td>${result?.sub_total}</td>
                        </tr>
                    </>
                )}
        </tbody>
    </table></small>;
    const columns = [
        {
            name: 'Order ID',
            selector: row => row.order_id,
        },
        {
            name: 'Order Date',
            selector: row => row.date_time,
        },
        {
            name: 'Price',
            selector: row => row.total_price,
        },
        {
            name: 'Order Status',
            selector: row => row.order_status,
        },
        {
            name: 'Payment Method',
            selector: row => row.payment_type,
        },
        {
            name: 'Invoice',
            selector: row => <a href={row.invoice} style={{color:'blue',textDecoration:'underline'}} target={'_blank'}>Invoice</a> ,
        },
    ];
  const getOrders = () => {
    setIsLoading(true);
    const userDetailsData = new FormData();
    userDetailsData.append('token', localStorage.getItem('token') );
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/products/user_orders`, userDetailsData, params)
        .then(res => {
          setIsLoading(false);
          if (res?.data?.status === 200) {
            setOrders(res?.data?.data);
              for(let i=0; i<res?.data?.data?.length; i++){
                  const Title = {
                      name: 'Order ID',
                      selector: row => row.order_id
                  }
              }

          }else {
            setOrders([]);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
  }
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='cart-section page-spacing'>
          <div className='breadcrumbs'>
              <Link to="/">Home / </Link> <strong>Orders</strong>
          </div>
          <div className='row'>
            <div className='col-md-3'>
                <div className='card'>
                <div className="card-body">
                    <h6 className='title'>MY ACCOUNT</h6>
                    <Sidebar id='2'/>
                </div>
                </div>
            </div>
            <div className='col-md-9'>
              <h6 className='title mb-4'>My Orders</h6>
                <DataTable
                    pagination
                    dense
                    columns={columns}
                    data={orders}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                />
            </div>
          </div>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default Orders;