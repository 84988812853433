import React from 'react';
import ReactDOM from 'react-dom';
import Home from './Home';
import Login from './shared/Login'
import Signup from './shared/Signup';
import ForgotPassword from './shared/ForgotPassword';
import ProductDetail from './ProductDetail';
import Shop from './Shop';
import Cart from './Cart';
import Checkout from './Checkout';
import OrderDetail from './OrderDetail';
import Orders from './Orders';
import Profile from './Profile';
import Shipping from './Shipping';
import Billing from './Billing';
import AboutUs from './AboutUs';
import KeyBrands from './KeyBrands';
import PrivacyPolicy from './PrivacyPolicy';
import Returns from './Returns';
import ShippingCoverage from './ShippingCoverage';
import TermsUse from './TermsUse';
import TermsConditions from './TermsConditions';
import ContactUs from './ContactUs';
import PageNotFound from './PageNotFound';
import OrderSuccess from './OrderSuccess';
import OrderFailed from './OrderFailed';
import Logout from './shared/Logout';
import Brands from './Brands';
import Category from './Category';
import Popular from './Popular';
import ManageCards from './ManageCards';
import AddCard from './AddCard';
import Blogs from './Blogs';
import BlogsDetailPage from './BlogDetailPage';

import { BrowserRouter, Routes, Route,} from "react-router-dom";
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/login" element={<Login />}></Route>
      <Route exact path="/signup" element={<Signup />}></Route>
      <Route exact path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route exact path="/reset-password/:email/:otp" element={<Home />}></Route>
      <Route exact path="/product/:id" element={<ProductDetail />}></Route>
      <Route exact path="/shop" element={<Shop />}></Route>
      <Route exact path="/cart" element={<Cart />}></Route>
      <Route exact path="/checkout" element={<Checkout />}></Route>
      <Route exact path="/order-detail" element={<OrderDetail />}></Route>
      <Route exact path="/orders" element={<Orders />}></Route>
      <Route exact path="/my-profile" element={<Profile />}></Route>
      <Route exact path="/shipping-address" element={<Shipping />}></Route>
      <Route exact path="/billing-address" element={<Billing />}></Route>
      <Route exact path="/about-us" element={<AboutUs />}></Route>
      <Route exact path="/key-brands" element={<KeyBrands />}></Route>
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      <Route exact path="/returns" element={<Returns />}></Route>
      <Route exact path="/shipping-coverage" element={<ShippingCoverage />}></Route>
      <Route exact path="/terms-conditions" element={<TermsConditions />}></Route>
      <Route exact path="/terms-use" element={<TermsUse />}></Route>
      <Route exact path="/contact-us" element={<ContactUs />}></Route>
      <Route exact path="/order-success/:type" element={<OrderSuccess />}></Route>
      <Route exact path="/order-failed" element={<OrderFailed />}></Route>
      <Route exact path="/popular" element={<Popular />}></Route>
      <Route exact path="/brands/:id" element={<Brands />}></Route>
      <Route exact path="/category/:id" element={<Category />}></Route>
      <Route exact path="/blogs" element={<Blogs />}></Route>
      <Route exact path="/blogs/:slug" element={<BlogsDetailPage />}></Route>
      <Route exact path="/manage-saved-cards" element={<ManageCards />}></Route>
      <Route exact path="/add-new-card" element={<AddCard />}></Route>
      <Route exact path="/logout" element={<Logout />}></Route>
      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
