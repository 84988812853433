import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';
import appConstant from './shared/constant/constant.json';

function PageNotFound() {
  return (
    <>
        {/* header  */}
        <Header/>
        <div className='page-error page-spacing mb-4'>
          <h6 className='title'>OOPS</h6>
          <p className='tagline'>Page Not Found</p>
          <img src={`${appConstant.CDN_URL}/images/404.webp`} alt='404' className='error-image' />
          <div className='d-flex justify-content-center'>
            <Link to='/' className='buttons'>&#129052; Back To Home</Link>
          </div>
        </div>
        {/* footer  */}
        <Footer/>
    </>
  )
};
export default PageNotFound;